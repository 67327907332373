import React from 'react'

import style from './style.module.scss'

const Card = ({
    title,
    children,
    titleGray,
    imageUrl,
    button,
    backgroundColor,
}) => {
    return (
        <div
            className={`${style.customCard} ${imageUrl ? style.imageUrl : ''}`}
            style={{ backgroundColor: backgroundColor }}
        >
            {imageUrl && (
                <img src={imageUrl} alt={title} className={style.thumbnail} />
            )}
            <div className={style.wrapper}>
                <h3 className={`${style.title}  ${titleGray && style.gray}`}>
                    {title}
                </h3>
                <div className={style.content}>{children}</div>
            </div>
            <div className={style.containerButton}>{button}</div>
        </div>
    )
}

export default Card
