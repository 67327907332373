import api from '../api'

export const handleDownload = (id) => {
    api.get(`/attachments/download/${id}`, { responseType: 'blob' })
        .then((response) => {
            const blob = response.data
            const contentType = response.headers['content-type']
            const contentDisposition = response.headers['content-disposition']

            let filename = ''

            if (
                contentDisposition &&
                contentDisposition.indexOf('attachment') !== -1
            ) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                let matches = filenameRegex.exec(contentDisposition)
                if (matches != null && matches[1])
                    filename = matches[1].replace(/['"]/g, '')
            }

            const file = new Blob([blob], { type: contentType })
            const fileURL = URL.createObjectURL(file)

            if (filename) {
                let linkDownload = document.createElement('a')

                linkDownload.setAttribute('href', fileURL)
                linkDownload.setAttribute('download', filename)

                linkDownload.click()
            }
        })
        .catch((error) => console.log(error))
}
