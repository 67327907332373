import React from 'react'
import { useNavigate } from 'react-router-dom'

import Loader from '../Loader'

import moment from 'moment'
import 'moment/locale/pt-br'

import useFetch from '../../admin/hook/useFetch'

import style from './style.module.scss'

const user = JSON.parse(localStorage.getItem('me'))

const Schedules = () => {
    let navigate = useNavigate()

    let query = `?author=${user?.id}&start_date:init=${moment
        .parseZone(new Date())
        .utc(true)
        .format('YYYY-MM-DD')} 08:00`

    let today = moment(new Date()).locale('pt-br').format('L')
    let newMap = new Map()

    const { data: workingstationOptions } = useFetch('/events?type=workspace')
    const { data: meetingRoomOptions } = useFetch('/events?type=meeting_room')
    const { isLoading, data: schedules } = useFetch(`/events/bookings${query}`)
    const { data: events } = useFetch('/events')

    const [meetingRoom, setMeetingRoom] = React.useState(0)
    const [workspace, setWorkspace] = React.useState(0)

    if (events.length !== 0) {
        for (let event of events) {
            const { id } = event
            newMap.set(id, { ...event })
        }
    }

    function handleSubmit(e) {
        e.preventDefault()
        const event_id = meetingRoom || workspace
        const params = new URLSearchParams()

        params.set('event_id', event_id)
        navigate(`/app/agenda?${params}`)
    }

    return (
        <div className={style.schedules}>
            <div className={style.boxHeader}>
                <svg
                    width="36"
                    height="31"
                    viewBox="0 0 36 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M17.77 14.49H23.62C23.8705 14.46 24.123 14.5258 24.327 14.6742C24.531 14.8225 24.6714 15.0425 24.72 15.29C24.7249 15.3164 24.7249 15.3436 24.72 15.37C25.82 20.0367 26.9133 24.7033 28 29.37C28.1222 29.6922 28.1222 30.0479 28 30.37C27.932 30.5366 27.8157 30.6791 27.6659 30.7789C27.5162 30.8787 27.34 30.9313 27.16 30.93C26.09 30.93 25.01 30.93 23.93 30.93C23.72 30.93 23.65 30.83 23.66 30.63V29.29C23.6761 28.4509 23.3588 27.6397 22.7777 27.0342C22.1966 26.4287 21.399 26.0784 20.56 26.06H15.05C14.215 26.022 13.399 26.3163 12.7805 26.8785C12.162 27.4408 11.7915 28.2252 11.75 29.06C11.74 29.1497 11.74 29.2403 11.75 29.33C11.75 29.75 11.75 30.17 11.75 30.58C11.75 30.99 11.66 30.92 11.41 30.92C10.41 30.92 9.36001 30.92 8.33001 30.92C8.08796 30.9396 7.84784 30.8638 7.66097 30.7087C7.47411 30.5536 7.35533 30.3315 7.33001 30.09C7.31622 29.9546 7.33332 29.8179 7.38001 29.69C7.74001 28.15 8.09001 26.62 8.45001 25.09C9.22334 21.85 9.99001 18.6033 10.75 15.35C10.7862 15.1115 10.9073 14.8942 11.0911 14.738C11.2748 14.5818 11.5088 14.4973 11.75 14.5H17.75L17.77 14.49ZM20.91 21.09C20.8898 20.2797 20.559 19.508 19.986 18.9347C19.4129 18.3614 18.6414 18.0302 17.8311 18.0096C17.0207 17.9891 16.2335 18.2808 15.6321 18.8243C15.0307 19.3678 14.6612 20.1217 14.6 20.93V21.01C14.6202 21.8204 14.951 22.592 15.5241 23.1653C16.0971 23.7387 16.8686 24.0698 17.6789 24.0904C18.4893 24.1109 19.2765 23.8193 19.8779 23.2757C20.4793 22.7322 20.8488 21.9783 20.91 21.17V21.09Z"
                        fill="white"
                    />
                    <path
                        d="M17.73 12.56H13.5C13.3995 12.5756 13.2969 12.5711 13.1981 12.5468C13.0993 12.5225 13.0063 12.4789 12.9245 12.4185C12.8427 12.358 12.7736 12.282 12.7214 12.1947C12.6691 12.1075 12.6346 12.0107 12.62 11.91C12.61 11.8337 12.61 11.7564 12.62 11.68C12.57 10.8298 12.5901 9.97694 12.68 9.13003C12.7845 8.65816 13.0331 8.23047 13.3915 7.90618C13.7498 7.58189 14.2001 7.37702 14.68 7.32C15.0087 7.24185 15.3532 7.26284 15.67 7.3803C15.9868 7.49776 16.2617 7.70644 16.46 7.98C16.7693 8.33652 17.1035 8.67069 17.46 8.98C17.5147 9.05734 17.5962 9.11151 17.6887 9.13186C17.7812 9.1522 17.8779 9.13728 17.96 9.09002C17.9984 9.05833 18.0321 9.02127 18.06 8.98C18.56 8.49 19.06 7.98001 19.54 7.49001C19.6175 7.40997 19.7109 7.34723 19.8143 7.30587C19.9178 7.26451 20.0287 7.24547 20.14 7.25002C20.5393 7.21007 20.9425 7.26032 21.3199 7.39705C21.6972 7.53379 22.039 7.75349 22.32 8.04C22.6807 8.43355 22.8836 8.94623 22.89 9.48C22.89 10.23 22.89 10.98 22.89 11.73C22.8982 11.8267 22.887 11.9241 22.8571 12.0164C22.8272 12.1088 22.7792 12.1942 22.7159 12.2678C22.6525 12.3413 22.5752 12.4015 22.4883 12.4448C22.4015 12.4882 22.3069 12.5137 22.21 12.52H17.74L17.73 12.56Z"
                        fill="white"
                    />
                    <path
                        d="M3.56 23.64H0.940003C0.250003 23.64 7.35616e-07 23.38 7.35616e-07 22.71V20.64C-0.00059942 19.6941 0.366051 18.7848 1.02269 18.1039C1.67933 17.4231 2.57467 17.0237 3.52 16.99C4.42382 16.968 5.30249 17.2888 5.97954 17.8879C6.65659 18.4871 7.08188 19.3202 7.17 20.22C7.21506 21.0627 7.21506 21.9073 7.17 22.75C7.17 23.36 6.87 23.63 6.26 23.64C5.38 23.65 4.47 23.64 3.56 23.64Z"
                        fill="white"
                    />
                    <path
                        d="M31.91 23.64H29.22C28.53 23.64 28.22 23.36 28.22 22.64C28.22 21.92 28.22 21.06 28.22 20.27C28.3067 19.4513 28.6632 18.6846 29.2335 18.0908C29.8038 17.4969 30.5554 17.1097 31.37 16.99C31.8371 16.9298 32.3115 16.9627 32.7659 17.0866C33.2202 17.2106 33.6456 17.4233 34.0174 17.7124C34.3892 18.0015 34.7001 18.3613 34.9323 18.771C35.1644 19.1808 35.3132 19.6325 35.37 20.1C35.3803 20.2365 35.3803 20.3735 35.37 20.51V22.73C35.37 23.32 35.11 23.59 34.52 23.6L31.91 23.64Z"
                        fill="white"
                    />
                    <path
                        d="M31.87 15.49C31.0792 15.4755 30.3262 15.1494 29.7747 14.5825C29.2232 14.0156 28.9179 13.2538 28.9251 12.463C28.9324 11.6721 29.2516 10.9162 29.8134 10.3595C30.3752 9.80281 31.1341 9.49052 31.925 9.49052C32.7159 9.49052 33.4748 9.80281 34.0366 10.3595C34.5984 10.9162 34.9176 11.6721 34.9249 12.463C34.9321 13.2538 34.6268 14.0156 34.0753 14.5825C33.5238 15.1494 32.7707 15.4755 31.98 15.49H31.87Z"
                        fill="white"
                    />
                    <path
                        d="M6.56 12.49C6.56 13.0833 6.38406 13.6634 6.05441 14.1567C5.72477 14.6501 5.25623 15.0346 4.70805 15.2616C4.15987 15.4887 3.55667 15.5481 2.97473 15.4324C2.39279 15.3166 1.85823 15.0309 1.43867 14.6113C1.01912 14.1918 0.733399 13.6572 0.617644 13.0753C0.501888 12.4933 0.561296 11.8901 0.788359 11.342C1.01542 10.7938 1.39995 10.3252 1.89329 9.99559C2.38664 9.66595 2.96665 9.49001 3.56 9.49001C4.35565 9.49001 5.11871 9.80609 5.68132 10.3687C6.24393 10.9313 6.56 11.6944 6.56 12.49Z"
                        fill="white"
                    />
                    <path
                        d="M17.74 6.49C17.1945 6.48803 16.6619 6.32447 16.2094 6.02C15.7568 5.71553 15.4046 5.2838 15.1972 4.77931C14.9899 4.27481 14.9366 3.72016 15.0443 3.18544C15.1519 2.65071 15.4155 2.15988 15.8019 1.77488C16.1883 1.38989 16.6801 1.12801 17.2152 1.02232C17.7503 0.916633 18.3047 0.971884 18.8085 1.18107C19.3122 1.39026 19.7427 1.74401 20.0455 2.19767C20.3484 2.65133 20.51 3.18454 20.51 3.72999C20.5113 4.09366 20.4405 4.454 20.3017 4.79011C20.1628 5.12623 19.9587 5.43144 19.701 5.68812C19.4434 5.94481 19.1374 6.14786 18.8008 6.2855C18.4642 6.42315 18.1036 6.49265 17.74 6.49Z"
                        fill="white"
                    />
                </svg>
                <h3>Reservas</h3>
            </div>
            <form onSubmit={handleSubmit}>
                <div className={style['form-group']}>
                    <label>Salas de Reunião</label>
                    <select
                        id="meetingRoom"
                        value={meetingRoom}
                        onChange={({ target }) =>
                            setMeetingRoom(Number(target.value))
                        }
                    >
                        <option value="0">Escolher Sala</option>
                        {meetingRoomOptions.map((item) => (
                            <option
                                key={item.id}
                                value={item.id}
                                disabled={workspace !== 0}
                            >
                                {item.title}
                            </option>
                        ))}
                    </select>
                </div>

                <div className={style['form-group']}>
                    <label>Estações de Trabalho</label>
                    <select
                        id="workspace"
                        value={workspace}
                        onChange={({ target }) =>
                            setWorkspace(Number(target.value))
                        }
                    >
                        <option value="0">Escolher Estação</option>
                        {workingstationOptions.map((item) => (
                            <option
                                key={item.id}
                                value={item.id}
                                disabled={meetingRoom !== 0}
                            >
                                {item.title}
                            </option>
                        ))}
                    </select>
                </div>
                <input
                    type="submit"
                    className={`button round small`}
                    value="Reservar"
                    disabled={meetingRoom === 0 && workspace === 0}
                />
            </form>

            <ul className={style.list}>
                {isLoading && <Loader />}
                {!isLoading && schedules.length === 0 && (
                    <small className="col-12 text-center">
                        Nenhum registro encontrado.
                    </small>
                )}
                {schedules
                    .sort((a, b) => new Date(a.start_at) - new Date(b.start_at))
                    .map((item) => (
                        <li
                            key={item.id}
                            className={
                                today ===
                                moment(item.start_at)
                                    .locale('pt-br')
                                    .format('L')
                                    ? style.today
                                    : ''
                            }
                        >
                            <span className={style.date}>
                                {moment(item.start_at)
                                    .locale('pt-br')
                                    .format('HH:mm') + 'h'}
                                <br />
                                {moment(item.start_at)
                                    .locale('pt-br')
                                    .format('DD/MM')}
                            </span>
                            <span className={style.title}>
                                {newMap && newMap.get(item.event_id)?.title}
                            </span>
                        </li>
                    ))}
            </ul>
        </div>
    )
}

export default Schedules
