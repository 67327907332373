import React from "react"
import BirthdayBox from "../BirthdayBox"
import LecturesEvents from "../LecturesEvents"
import Schedules from "../Schedules"
import Spacer from "../Spacer"

const RightSidebar = () => {
  return (
    <>
      <Schedules />
      <Spacer size={20} />
      <BirthdayBox />
      <Spacer size={20} />
      <LecturesEvents />
    </>
  )
}
export default RightSidebar