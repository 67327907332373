import React from 'react'
import SiteIcon from '@rsuite/icons/Site'

import HeaderAdmin from '../../components/Header'
import SidebarAdmin from '../../components/Sidebar'

import Committee from '../Committee'
import Communication from '../Communication'
import CommunicationLink from '../CommunicationLink'
import Education from '../Education'
import Branches from '../Branches'
import Users from '../Users'
import Services from '../Services'
import Researches from '../Researches'
import Contacts from '../Contacts'
import AcademicFiles from '../AcademicFiles'
import Academic from '../Academic'
import AcademicCategories from '../AcademicCategories'
import BranchesCategories from '../BranchesCategories'
import ResearchesCategories from '../ResearchesCategories'
import ServicesCategories from '../ServicesCategories'
import Handbooks from '../Handbooks'
import HandbooksCategories from '../HandbooksCategories'
import EmployeesTabs from '../EmployeesTabs'
import EmployeesLinks from '../EmployeesLinks'
import EmployeesClients from '../EmployeesClients'
import EmployeesBoxes from '../EmployeesBoxes'
import Lecture from '../Lecture'
import LectureScheduling from '../LectureScheduling'
import Notices from '../Notices'
import TravelRequest from '../TravelRequest'
import TravelRequestLink from '../TravelRequestLink'

import 'rsuite/dist/rsuite.min.css'
import style from './style.module.scss'

function Dashboard() {
    const [active, setActive] = React.useState('Dashboard')

    return (
        <div className={style.dashboardContainer}>
            <HeaderAdmin />
            <div className="container mt-5">
                <div className="row">
                    <SidebarAdmin active={active} setActive={setActive} />

                    <div className="col-12 col-lg-9">
                        {active === 'Dashboard' && (
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body rounded bg-light text-center">
                                            <span>
                                                <SiteIcon
                                                    className="text-muted mb-3"
                                                    style={{
                                                        width: '56px',
                                                        height: '56px',
                                                    }}
                                                />
                                            </span>
                                            <h1 className="title mb-0">
                                                Bem-vindo a área administativa.
                                            </h1>
                                            <p className="text-muted">
                                                Sistema administativo interno da
                                                plataforma de intranet BFBM.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {active === 'Comitês' && <Committee title={active} />}
                        {active === 'Comunicação' && (
                            <Communication title={active} />
                        )}
                        {active === 'Comunicação - Link' && (
                            <CommunicationLink title={active} />
                        )}
                        {active === 'BFBM Educação' && (
                            <Education title={active} />
                        )}
                        {active === 'Ramais Úteis' && (
                            <Branches title={active} />
                        )}
                        {active === 'Ramais Úteis - Categoria' && (
                            <BranchesCategories title={active} />
                        )}
                        {active === 'Usuários' && <Users title={active} />}
                        {active === 'Serviços' && <Services title={active} />}
                        {active === 'Serviços - Categoria' && (
                            <ServicesCategories title={active} />
                        )}

                        {active === 'Contencioso e Pesquisa' && (
                            <Researches title={active} />
                        )}
                        {active === 'Contencioso e Pesquisa - Categoria' && (
                            <ResearchesCategories title={active} />
                        )}
                        {active === 'Solicitação de Viagens' && (
                            <TravelRequest title={active} />
                        )}
                        {active === 'Solicitação de Viagens - Link' && (
                            <TravelRequestLink title={active} />
                        )}
                        {active === 'Contatos' && <Contacts title={active} />}

                        {active === 'Manuais' && <Handbooks title={active} />}
                        {active === 'Manuais - Categoria' && (
                            <HandbooksCategories title={active} />
                        )}
                        {active === 'BFBM Acadêmico' && (
                            <Academic title={active} />
                        )}
                        {active === 'BFBM Acadêmico - Categoria' && (
                            <AcademicCategories title={active} />
                        )}
                        {active === 'BFBM Acadêmico - Arquivo' && (
                            <AcademicFiles title={active} />
                        )}

                        {active === 'Gente' && <EmployeesTabs title={active} />}
                        {active === 'Gente - Links' && (
                            <EmployeesLinks title={active} />
                        )}
                        {active === 'Gente - Clientes' && (
                            <EmployeesClients title={active} />
                        )}
                        {active === 'Gente - Equipes' && (
                            <EmployeesBoxes title={active} />
                        )}
                        {active === 'Palestras' && <Lecture title={active} />}
                        {active === 'Agendar Palestra' && (
                            <LectureScheduling title={active} />
                        )}
                        {active === 'Avisos' && <Notices title={active} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
