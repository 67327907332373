import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import route from './_resources/routes.json'

import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Comites from './pages/Comites'
import Education from './pages/Education'
import Branches from './pages/Branches'
import TravelRequest from './pages/TravelRequest'
import Manuals from './pages/Manuals'
import Litigation from './pages/Litigation'
import People from './pages/People'
import Services from './pages/Services'
import Profile from './pages/Profile'
import Schedule from './pages/Schedule'
import Comunication from './pages/Comunication'

/* Admin 
------------------------ */
import AdminPanel from './pages/AdminPanel'

import './scss/global.scss'

import { PrivateRoute } from './components/PrivateRoute'

function App() {
    const user = localStorage.getItem('user')
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path={route.HOME_PAGE}
                    element={
                        !user ? (
                            <Login />
                        ) : (
                            <Navigate to={route.DASHBOARD_PAGE} />
                        )
                    }
                />
                <Route
                    path={route.DASHBOARD_PAGE}
                    exact={true}
                    element={
                        <PrivateRoute>
                            <Dashboard />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={route.COMMITTE_PAGE}
                    exact={true}
                    element={
                        <PrivateRoute>
                            <Comites />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={route.EDUCATION_PAGE}
                    exact={true}
                    element={
                        <PrivateRoute>
                            <Education />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={route.BRANCHES_PAGE}
                    exact={true}
                    element={
                        <PrivateRoute>
                            <Branches />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={route.COMMUNICATION_PAGE}
                    exact={true}
                    element={
                        <PrivateRoute>
                            <Comunication />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={route.TRAVEL_PAGE}
                    exact={true}
                    element={
                        <PrivateRoute>
                            <TravelRequest />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={route.MANUALS_PAGE}
                    exact={true}
                    element={
                        <PrivateRoute>
                            <Manuals />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={route.LITIGATION_PAGE}
                    exact={true}
                    element={
                        <PrivateRoute>
                            <Litigation />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={route.PEOPLE_PAGE}
                    exact={true}
                    element={
                        <PrivateRoute>
                            <People />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={route.SERVICES_PAGE}
                    exact={true}
                    element={
                        <PrivateRoute>
                            <Services />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={route.PROFILE_PAGE}
                    exact={true}
                    element={
                        <PrivateRoute>
                            <Profile />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={route.SCHEDULE_PAGE}
                    exact={true}
                    element={
                        <PrivateRoute>
                            <Schedule />
                        </PrivateRoute>
                    }
                />

                {/* Admin Routes */}
                <Route
                    path="admin"
                    exact={true}
                    element={
                        <PrivateRoute>
                            <AdminPanel />
                        </PrivateRoute>
                    }
                />

                <Route from="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App
