import React from 'react'
import { Button } from 'react-bootstrap'

import style from './style.module.scss'

const Committee = ({ buttonUrl, title, image, children }) => {
    return (
        <div className={style.committee}>
            <div className={style.wrapper}>
                <img src={image} alt={title} className={style.image} />
                <h3 className={style.title}>{title}</h3>
                <div className={style.content}>{children}</div>
            </div>
            {/* <Button variant="primary" className="button">
                Participar
            </Button> */}
        </div>
    )
}

export default Committee
