import React from 'react'
import Dropzone from 'react-dropzone'
import FileDownloadIcon from '@rsuite/icons/FileDownload'

import api from '../../../../api'
import { authHeader } from '../../../../_helpers/auth-header'

function UploadDropzone({ setUpload }) {
    const [isError, setIsError] = React.useState(null)
    const [isUpload, setIsUpload] = React.useState(null)

    function handleUpload(files) {
        const data = new FormData()
        data.append('attachment', files[0])

        api.post('/attachments/upload', data, { authHeader })
            .then((response) => {
                setIsError(null)
                setIsUpload(response.data)
                setUpload(response.data)
            })
            .catch((error) => {
                const err = error.response.data.errors
                if (error.response.data.errors) {
                    setIsError(err[Object.keys(err)].toString())
                    setIsUpload(null)
                }
            })
    }

    return (
        <Dropzone onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p
                            style={{
                                cursor: 'pointer',
                                border: '2px dashed #6c757d',
                            }}
                            className={`d-flex align-items-center rounded p-4 text-muted ${
                                isError && isUpload === null
                                    ? 'border-danger'
                                    : ''
                            }`}
                        >
                            <FileDownloadIcon style={{ fontSize: '2rem' }} />
                            <span className="d-block ml-3">
                                {!isError && isUpload
                                    ? isUpload.name
                                    : 'Upload de arquivos'}
                            </span>
                        </p>
                    </div>
                    {isError && isUpload === null && (
                        <small className="d-block bg-danger rounded text-light py-1 px-3">
                            {isError}
                        </small>
                    )}
                </section>
            )}
        </Dropzone>
    )
}

export default UploadDropzone
