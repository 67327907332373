import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Button, Modal } from 'react-bootstrap'
import TopBar from '../components/TopBar'
import VerticalNavigation from '../components/VerticalNavigation'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'
import Committee from '../components/Committee'
import Loader from '../components/Loader'
import Card from '../components/Card'

import api from '../api'
import { authHeader } from '../_helpers'

import style from '../scss/pages/dashboard.module.scss'

const Comites = () => {
    const [show, setShow] = useState(false)
    const [committees, setCommittees] = useState([])
    const [loading, setLoading] = useState(true)
    const [isCommittee, setIsCommittee] = useState(null)

    const getData = () => {
        setLoading(true)
        api.get('/committees')
            .then(function (response) {
                setCommittees(response.data)
                setLoading(false)
            })
            .catch(function (err) {
                console.error(
                    'Ops! Houve um erro ao realizar as requisições: ' + err
                )
                setLoading(false)
            })
    }

    const handleClick = (id) => {
        setShow(true)
        getOneCommittees(id)
    }

    const getOneCommittees = (id) => {
        api.get(`/committees/${id}`).then((response) => {
            if (response.status === 200) {
                setIsCommittee(response.data)
            }
        })
    }

    const confirmParticiopation = () => {
        api.get('/auth/me').then((response) => {
            if (response.status === 200) {
                const { id: user_id } = response.data
                const { id: committee_id, url: email_to } = isCommittee

                postSendEmail({ email_to, user_id, committee_id })
                setShow(false)
            }
        })
    }

    const postSendEmail = (data) => {
        const { committee_id, user_id, email_to } = data
        const ROUTE_SIGNUP = `/signup/committees/${committee_id}`

        api.post(
            ROUTE_SIGNUP,
            { to: email_to, user_id: user_id },
            { authHeader }
        ).then((response) => console.log(response))
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <section className={`content ${style.dashboard}`}>
            <Modal show={show} centered onHide={() => setShow(false)}>
                <Modal.Body>
                    <div className="p-4">
                        <strong className="d-block text-center m-3">
                            Confirmar a participação no{' '}
                            {committees &&
                                committees.find(
                                    (item) => item.id === isCommittee?.id
                                )?.title}
                            ?
                        </strong>
                        <Button
                            variant="primary"
                            className="button"
                            onClick={() => confirmParticiopation()}
                        >
                            Confirmar
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            <TopBar />
            <Spacer size={90} />
            <Container>
                <Row>
                    <Col lg={3}>
                        <VerticalNavigation />
                    </Col>
                    <Col lg={9}>
                        <Row>
                            <Col>
                                <h2 className="title blue">Comitês</h2>
                            </Col>
                        </Row>
                        <Row>
                            {loading && <Loader />}
                            {committees.length === 0 && !loading && (
                                <Card
                                    title="Nenhum comitê foi encontrado!"
                                    titleGray
                                />
                            )}
                            {committees.map((committee) => (
                                <Col lg={6} key={committee.id}>
                                    <Committee
                                        title={committee.title}
                                        image={committee.image}
                                        buttonUrl={committee.url}
                                    >
                                        <p>{committee.description}</p>

                                        <Button
                                            variant="primary"
                                            className="button"
                                            onClick={() =>
                                                handleClick(committee.id)
                                            }
                                        >
                                            Participar
                                        </Button>
                                    </Committee>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Footer colorBlue={false} />
        </section>
    )
}

export default Comites
