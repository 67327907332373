import React from 'react'
import api from '../../api'

export default function useFetch(url) {
    const [isFetching, setIsFetching] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(true)
    const [isError, setIsError] = React.useState(null)
    const [data, setData] = React.useState([])

    React.useEffect(() => {
        api.get(url)
            .then((response) => {
                if (response.data && response.status === 200) {
                    setIsFetching(response)
                    setData(response.data)
                }
            })
            .catch((error) => {
                setIsError(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    return {
        isLoading,
        isFetching,
        isError,
        data,
    }
}
