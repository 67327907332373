import React from 'react'
import { Button } from 'rsuite'

import api from '../../../../api'
import { authHeader } from '../../../../_helpers/auth-header'

function DrawerForm({ action, setAction }) {
    const {
        data: { id, title, image },
        behavior,
    } = action

    const initialState = {
        title: behavior === 'update' ? title : '',
        image: behavior === 'update' ? image : '',
    }

    const initialUpload = {
        preview: null,
        origin: null,
        base_64: null,
    }

    const [form, setForm] = React.useState({ ...initialState })
    const [upload, setUpload] = React.useState(initialUpload)
    const [isError, setIsError] = React.useState(null)

    function handleChange({ target }) {
        const { id, value } = target
        setForm({ ...form, [id]: value })
    }

    async function handleUpload({ target }) {
        const reader = new FileReader()

        reader.readAsDataURL(target.files[0])
        reader.onloadend = () => {
            setUpload({
                preview: URL.createObjectURL(target.files[0]),
                origin: target.files[0],
                base_64: reader.result,
            })
            setForm({ ...form, image: reader.result })
        }
    }

    function update(id) {
        api.put(
            `/communications/${id}`,
            {
                title: form.title,
                image: upload.base_64,
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 200) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function create() {
        api.post(
            '/communications',
            {
                title: form.title,
                image: upload.base_64,
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 200) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function handleSubmit(e) {
        e.preventDefault()

        if (behavior === 'create') {
            create()
            return
        }

        if (behavior === 'update') {
            update(id)
            return
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <span className="form-group mb-4 d-block">
                {upload.preview || form.image ? (
                    <div className="preview">
                        <img
                            style={{ width: '90px', height: '90px' }}
                            className="rounded mx-auto d-block mb-3 img-fluid"
                            src={upload.preview || form.image}
                        />
                        <Button
                            appearance="primary"
                            size="xs"
                            color="red"
                            onClick={() => {
                                setUpload({ ...upload, preview: null })
                                setForm({ ...form, image: '' })
                            }}
                        >
                            Excluir
                        </Button>
                    </div>
                ) : (
                    <>
                        <label>Imagem</label>
                        <input
                            className="form-control"
                            id="image"
                            type="file"
                            onChange={handleUpload}
                            required
                        />
                    </>
                )}
            </span>

            <span className="form-group mb-4 d-block">
                <label>Título</label>
                <input
                    className="form-control"
                    id="title"
                    type="text"
                    onChange={handleChange}
                    value={form.title}
                    required
                />
            </span>

            <span className="form-group">
                <button className="btn btn-primary">
                    {behavior === 'create' ? 'Salvar' : 'Atualizar'}
                </button>
            </span>

            {isError && (
                <div className="row">
                    <div className="col">
                        {Object.keys(isError).map((item) => (
                            <p
                                key={item}
                                className="mt-2 bg-danger text-white rounded p-3"
                            >
                                {isError[item]}
                            </p>
                        ))}
                    </div>
                </div>
            )}
        </form>
    )
}

export default DrawerForm
