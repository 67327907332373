import React from 'react'
import { Table } from 'rsuite'

const { Column, HeaderCell, Cell } = Table

function TableComponent({ data, config, update, destroy }) {
    return (
        <Table
            data={data}
            virtualized
            autoHeight={true}
            loading={!data ? true : false}
            renderEmpty={() => {
                return (
                    <p
                        className="d-flex align-items-center justify-content-center text-muted "
                        style={{
                            height: '400px',
                            backgroundColor: '#fff!important',
                            fontSize: '.85rem',
                        }}
                    >
                        Nenhum registro encontrado.
                    </p>
                )
            }}
        >
            {config.map(({ label, key, width, fixed, content }) => (
                <Column
                    style={{ fontSize: '.85rem', color: '#565656' }}
                    key={label}
                    flexGrow={!width ? 1 : 0}
                    width={width}
                    fixed={fixed}
                >
                    <HeaderCell>{label}</HeaderCell>
                    {!content ? (
                        <Cell dataKey={key}></Cell>
                    ) : (
                        <Cell>{(item) => content(item)}</Cell>
                    )}
                </Column>
            ))}
            <Column flexGrow={0} align="center" fixed="right">
                <HeaderCell></HeaderCell>
                <Cell>{(rowData) => update(rowData)}</Cell>
            </Column>

            <Column flexGrow={0} align="center" fixed="right">
                <HeaderCell></HeaderCell>
                <Cell>{(rowData) => destroy(rowData)}</Cell>
            </Column>
        </Table>
    )
}

export default TableComponent
