import React, { useEffect, useState } from 'react'
import axios from 'axios'

import style from './style.module.scss'
import PostBox from '../PostBox'
import Card from '../Card'
import Loader from '../Loader'

const PostList = () => {
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let url = 'https://www.bfbm.com.br/wp-json/wp/v2/posts?_embed'
        axios
            .get(url)
            .then((response) => {
                // console.log(response.data)
                const getPosts = response.data
                setPosts(getPosts)
                setLoading(false)
            })
            .catch((error) => {
                console.log('Erro', error)
            })
    }, [])

    // console.log(posts.length)

    return (
        <div className={style.postList}>
            <h2 className="title">Avisos e Notícias</h2>
            {loading && <Loader />}
            {posts.length === 0 && loading === false && (
                <Card title="Nenhuma matéria encontrada!" titleGray />
            )}
            {posts.map((item) => {
                return (
                    <PostBox
                        key={item.id}
                        title={item.title.rendered}
                        link={item.link}
                        publishDate={item.date}
                        author={item._embedded.author[0].name}
                        thumbnail={item.thumbnail}
                    >
                        {/* {item.content.rendered} */}
                    </PostBox>
                )
            })}
        </div>
    )
}

export default PostList
