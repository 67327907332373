import React from 'react'
import parse from 'html-react-parser'
import { Col, Container, Row, Tabs, Tab } from 'react-bootstrap'
import TopBar from '../components/TopBar'
import VerticalNavigation from '../components/VerticalNavigation'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'
import Card from '../components/Card'
import UsersEmailPhoneBox from '../components/UsersEmailPhoneBox'
import Button from '../components/Button'
import Loader from '../components/Loader'

import api from '../api'
import useFetch from '../admin/hook/useFetch'
import { handleDownload } from '../_helpers/handleDownload'

import style from '../scss/pages/dashboard.module.scss'

const TravelRequest = () => {
    const { data: posts, isLoading } = useFetch('/posts')
    const { data: postLinks } = useFetch('/post-links')
    const { data: contactLocations } = useFetch('/contacts/locations')
    const { data: contacts } = useFetch('/contacts')

    return (
        <section className={`content ${style.dashboard}`}>
            <TopBar />
            <Spacer size={90} />
            <Container>
                <Row>
                    <Col lg={3}>
                        <VerticalNavigation />
                    </Col>
                    <Col lg={9}>
                        {isLoading && <Loader />}
                        {!isLoading && posts.length === 0 && (
                            <p>Nenhum registro encontrado.</p>
                        )}
                        {!isLoading &&
                            posts.length !== 0 &&
                            posts.map(({ id, title, content }) => (
                                <Row key={id}>
                                    <Row>
                                        <Col>
                                            <h2 className="title blue">
                                                {title}
                                            </h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <Row>
                                                    <Col lg={12}>
                                                        {parse(content)}
                                                        <Spacer size="20" />
                                                    </Col>
                                                    {postLinks
                                                        .filter(
                                                            ({ post_id }) =>
                                                                post_id === id
                                                        )
                                                        .map(
                                                            ({
                                                                id,
                                                                title,
                                                                url,
                                                                attachment_id,
                                                            }) => (
                                                                <Col
                                                                    lg={6}
                                                                    key={id}
                                                                >
                                                                    {!attachment_id ? (
                                                                        <Button
                                                                            url={
                                                                                url
                                                                            }
                                                                            target="_blank"
                                                                            text={
                                                                                title
                                                                            }
                                                                            noIcon
                                                                        />
                                                                    ) : (
                                                                        <Button
                                                                            onClick={() => {
                                                                                handleDownload(
                                                                                    attachment_id
                                                                                )
                                                                            }}
                                                                            text={
                                                                                title
                                                                            }
                                                                            isDownload
                                                                        />
                                                                    )}
                                                                </Col>
                                                            )
                                                        )}
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card title="Contato para dúvidas">
                                                <Tabs
                                                    defaultActiveKey={
                                                        contactLocations[0]
                                                            ?.state_id
                                                    }
                                                    id="uncontrolled-tab-example"
                                                    className="mb-3"
                                                >
                                                    {contactLocations &&
                                                        contactLocations.map(
                                                            ({
                                                                state_id,
                                                                state_uf,
                                                            }) => (
                                                                <Tab
                                                                    key={
                                                                        state_id
                                                                    }
                                                                    eventKey={
                                                                        state_id
                                                                    }
                                                                    title={
                                                                        state_uf
                                                                    }
                                                                >
                                                                    <Row>
                                                                        {contacts
                                                                            .filter(
                                                                                (
                                                                                    contact
                                                                                ) =>
                                                                                    contact.state_id ===
                                                                                    state_id
                                                                            )
                                                                            .map(
                                                                                ({
                                                                                    id,
                                                                                    name,
                                                                                    email,
                                                                                }) => (
                                                                                    <UsersEmailPhoneBox
                                                                                        key={
                                                                                            id
                                                                                        }
                                                                                        name={
                                                                                            name
                                                                                        }
                                                                                        info={
                                                                                            email
                                                                                        }
                                                                                    />
                                                                                )
                                                                            )}
                                                                    </Row>
                                                                </Tab>
                                                            )
                                                        )}
                                                </Tabs>
                                                {/* 
                                                <Tabs
                                                    defaultActiveKey="RJ"
                                                    id="uncontrolled-tab-example"
                                                    className="mb-3"
                                                >
                                                    <Tab
                                                        eventKey="RJ"
                                                        title="RJ"
                                                    >
                                                        <Row>
                                                            <UsersEmailPhoneBox
                                                                name="Aline"
                                                                info="aferraz@bfbm.com.br"
                                                            />
                                                        </Row>
                                                    </Tab>
                                                    <Tab
                                                        eventKey="SP"
                                                        title="SP"
                                                    >
                                                        <Row>
                                                            <UsersEmailPhoneBox
                                                                name="Mitali"
                                                                info="msantos@bfbm.com.br"
                                                            />
                                                        </Row>
                                                    </Tab>
                                                    <Tab
                                                        eventKey="BSB"
                                                        title="BSB"
                                                    >
                                                        <Row>
                                                            <UsersEmailPhoneBox
                                                                name="Dimitra"
                                                                info="dalencar@bfbm.com.br"
                                                            />
                                                        </Row>
                                                    </Tab>
                                                </Tabs> */}
                                            </Card>
                                        </Col>
                                    </Row>
                                </Row>
                            ))}
                    </Col>
                </Row>
            </Container>
            <Footer colorBlue={false} />
        </section>
    )
}

export default TravelRequest
