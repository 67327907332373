import React from 'react'
import { IconButton } from 'rsuite'
import { Exit } from '@rsuite/icons'

import routes from '../../../_resources/routes.json'

import { useDispatch } from 'react-redux'
import { userActions } from '../../../_redux/actions'

function ButtonLogout() {
    const dispatch = useDispatch()

    const getLogout = () => {
        dispatch(userActions.logout())
        window.location.assign(routes.HOME_PAGE)
    }

    return (
        <IconButton
            appearance="ghost"
            className="ml-lg-auto text-light border-light"
            style={{
                textDecoration: 'none',
                borderRadius: '24px',
            }}
            icon={
                <Exit
                    style={{
                        backgroundColor: 'transparent',
                        paddingRight: '0px',
                    }}
                    className="text-light"
                />
            }
            onClick={getLogout}
        >
            Logout
        </IconButton>
    )
}

export default ButtonLogout
