import React from 'react'

import { authHeader } from '../../../../_helpers/auth-header'
import useFetch from '../../../hook/useFetch'
import api from '../../../../api'

function DrawerForm({ action, setAction }) {
    const {
        data: { id, title, url, employees_id },
        behavior,
    } = action

    const initialState = {
        title: behavior === 'update' ? title : '',
        url: behavior === 'update' ? url : '',
        employees_id: behavior === 'update' ? employees_id : 0,
    }

    const { data: employeesTabs } = useFetch('/employees/tabs')

    const [form, setForm] = React.useState({ ...initialState })
    const [isError, setIsError] = React.useState(null)

    function handleChange({ target }) {
        const { id, value } = target
        setForm({ ...form, [id]: value })
    }

    function update(id) {
        api.put(
            `/employees/links/${id}`,
            {
                title: form.title,
                url: form.url,
                employees_id: form.employees_id,
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 200) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function create() {
        api.post(
            '/employees/links',
            {
                title: form.title,
                url: form.url,
                employees_id: form.employees_id,
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 200) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function handleSubmit(e) {
        e.preventDefault()

        if (behavior === 'create') {
            create()
            return
        }

        if (behavior === 'update') {
            update(id)
            return
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <span className="form-group mb-4 d-block">
                <label>Título</label>
                <input
                    className="form-control"
                    id="title"
                    type="text"
                    onChange={handleChange}
                    value={form.title}
                    required
                />
            </span>

            <span className="form-group mb-4 d-block">
                <label>URL</label>
                <input
                    className="form-control"
                    id="url"
                    type="text"
                    onChange={handleChange}
                    value={form.url}
                    required
                />
            </span>

            <span className="form-group mb-4 d-block">
                <label>Categoria</label>
                <select
                    id="employees_id"
                    className="form-select"
                    onChange={handleChange}
                    value={form.employees_id}
                >
                    <option value="0" disabled>
                        Selecione a categoria
                    </option>
                    {employeesTabs
                        .filter(
                            (tabs) => tabs.title.toLowerCase() !== 'equipes'
                        )
                        .map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.title}
                            </option>
                        ))}
                </select>
            </span>

            <span className="form-group">
                <button className="btn btn-primary">
                    {behavior === 'create' ? 'Salvar' : 'Atualizar'}
                </button>
            </span>

            {isError && (
                <div className="row">
                    <div className="col">
                        <p className="mt-2 bg-danger text-white rounded p-3">
                            {isError[Object.keys(isError)]}
                        </p>
                    </div>
                </div>
            )}
        </form>
    )
}

export default DrawerForm
