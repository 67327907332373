import React from 'react'
import UploadDropzone from '../UploadDropzone'

import useFetch from '../../../hook/useFetch'
import api from '../../../../api'
import { authHeader } from '../../../../_helpers/auth-header'

function DrawerForm({ action, setAction }) {
    const {
        data: { id, title, url, handbook_categories_id, attachment_id },
        behavior,
    } = action

    const initialState = {
        title: behavior === 'update' ? title : '',
        url: behavior === 'update' ? url : '',
        handbook_categories_id:
            behavior === 'update' ? handbook_categories_id : 0,
        attachment_id: behavior === 'update' ? attachment_id : null,
    }

    const [type, setType] = React.useState(0)
    const [form, setForm] = React.useState({ ...initialState })
    const [upload, setUpload] = React.useState(null)
    const [isError, setIsError] = React.useState(null)

    const { data: handbook_categories } = useFetch('/handbook-categories')

    function handleChange({ target }) {
        const { id, value } = target
        setForm({ ...form, [id]: value })
    }

    function update(id) {
        api.put(
            `/handbooks/${id}`,
            {
                title: form.title,
                url: form.url,
                handbook_categories_id: form.handbook_categories_id,
                attachment_id: form.attachment_id,
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 200) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function create() {
        api.post(
            '/handbooks',
            {
                title: form.title,
                url: form.url,
                handbook_categories_id: form.handbook_categories_id,
                attachment_id: form.attachment_id,
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 200) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function handleSubmit(e) {
        e.preventDefault()

        if (behavior === 'create') {
            create()
            return
        }

        if (behavior === 'update') {
            update(id)
            return
        }
    }

    function validateURL(url) {
        const valid = /^(ftp|http|https):\/\/[^ "]+$/.test(url)
        return valid
    }

    React.useEffect(() => {
        if (upload) {
            setForm({
                ...form,
                url: upload.name ?? form.url,
                attachment_id: upload.id ?? form.attachment_id,
            })
        }
    }, [upload])

    React.useEffect(() => {
        if (behavior === 'update') {
            if (validateURL(form.url)) {
                setType('Link')
            } else {
                setType('Arquivo')
            }
        }
    }, [])

    return (
        <form onSubmit={handleSubmit}>
            <span className="form-group mb-4 d-block">
                <label>Título</label>
                <input
                    className="form-control"
                    id="title"
                    type="text"
                    onChange={handleChange}
                    value={form.title}
                    required
                />
            </span>

            <span className="form-group mb-4 d-block">
                <label>Tipo de Link</label>
                <select
                    id="type"
                    className="form-select"
                    onChange={({ target }) => {
                        if (behavior === 'update') {
                            setForm({ ...form, url: '' })
                        }
                        setType(target.value)
                    }}
                    value={type}
                >
                    <option value="0" disabled>
                        Selecione o tipo
                    </option>
                    {['Arquivo', 'Link'].map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
            </span>

            {type === 0 && null}
            {type === 'Link' && (
                <span className="form-group mb-4 d-block">
                    <label>URL</label>
                    <input
                        className="form-control"
                        id="url"
                        type="text"
                        onChange={handleChange}
                        value={form.url}
                        required
                    />
                </span>
            )}

            {type === 'Arquivo' && (
                <span className="form-group mb-4 d-block">
                    <UploadDropzone setUpload={setUpload} />

                    <label>URL</label>
                    <input
                        className="form-control"
                        id="url"
                        type="text"
                        onChange={handleChange}
                        value={form.url}
                        required
                        disabled
                    />
                </span>
            )}

            <span className="form-group mb-4 d-block">
                <label>Categoria</label>
                <select
                    id="handbook_categories_id"
                    className="form-select"
                    onChange={handleChange}
                    value={form.handbook_categories_id}
                >
                    <option value="0" disabled>
                        Selecione o categoria
                    </option>
                    {handbook_categories &&
                        handbook_categories.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.title}
                            </option>
                        ))}
                </select>
            </span>

            <span className="form-group">
                <button className="btn btn-primary">
                    {behavior === 'create' ? 'Salvar' : 'Atualizar'}
                </button>
            </span>

            {isError && (
                <div className="row">
                    <div className="col">
                        <p className="mt-2 bg-danger text-white rounded p-3">
                            {isError[Object.keys(isError)]}
                        </p>
                    </div>
                </div>
            )}
        </form>
    )
}

export default DrawerForm
