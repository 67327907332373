import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import TopBar from '../components/TopBar'
import VerticalNavigation from '../components/VerticalNavigation'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'
import Card from '../components/Card'
import api from "../api"
import style from '../scss/pages/dashboard.module.scss'
import SearchBfbmEducation from '../components/SearchBfbmEducation'
import Loader from "../components/Loader"
import axios from "axios"

const Education = () => {
    const [dataFilesCategories, setDataFilesCategories] = useState([]);
    const [dataFiles, setDataFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataFilesEducations, setDataFilesEducations] = useState([]);

    const fetchData = () => {
        setLoading(true)
    
        const educationsURL = "/educations"
        const academicCategoriesURL = "/academic-categories"
        const academicFilesURL = "/academic-files"

        const dataEducations = api.get(educationsURL)
        const dataAcademicCategories = api.get(academicCategoriesURL)
        const dataAcademicFiles = api.get(academicFilesURL)
    
        axios.all([dataEducations, dataAcademicCategories, dataAcademicFiles]).then(
          axios.spread((...responses) => {
            setDataFilesEducations(responses[0].data)
            setDataFilesCategories(responses[1].data)
            setDataFiles(responses[2].data)
            setLoading(false)
          })
        )
        .catch((err) => {
          console.error("Ops! Houve um erro ao realizar as requisições: " + err);
          setLoading(false)
        });
      }

    useEffect(() => {
      fetchData()
    }, [])

    return (
        <section className={`content ${style.dashboard}`}>
            <TopBar />
            <Spacer size={90} />
            <Container>
                <Row>
                    <Col lg={3}>
                        <VerticalNavigation />
                    </Col>
                    <Col lg={9}>
                        <Row>
                            <Col>
                                <h2 className='title blue'>BFBM Educação</h2>
                            </Col>
                        </Row>
                        <Row>
                            {dataFilesEducations.map((education) => (
                                <Col lg={6} key={education.id}>
                                    <Card title={education.title}
                                        button={<Button variant="primary" className="button"
                                            href={education.url}
                                            target='_blank'>Acesse
                                        </Button>} >
                                        <p>{education.description}</p>
                                        <br />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <Col>
                                {loading && <Loader />}
                                {(dataFiles.length === 0 && loading === false) &&
                                    <Card title="Nenhum conteúdo encontrado" titleGray />}
                                {(dataFiles.length !== 0 && loading === false) &&
                                    <Card title="BFBM Acadêmico">
                                        <p>Aqui você acessa as palestras e cursos ministrados exclusivamente para o
                                            #Time
                                            BFBM.</p>
                                        <SearchBfbmEducation files={dataFiles} categories={dataFilesCategories} />
                                    </Card>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container >
            <Footer colorBlue={false} />
        </section >
    );
}

export default Education