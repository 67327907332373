import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Stack } from 'react-bootstrap'
import TopBar from '../components/TopBar'
import VerticalNavigation from '../components/VerticalNavigation'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'
import Card from '../components/Card'
import api from '../api'
import axios from 'axios'

import style from '../scss/pages/dashboard.module.scss'
import UsersEmailPhoneBox from '../components/UsersEmailPhoneBox'
import Loader from '../components/Loader'

const Branches = () => {
    const [loading, setLoading] = useState(true)
    const [branchCategories, setBranchCategories] = useState([])
    const [branchLines, setBranchLines] = useState([])

    const fetchData = () => {
        setLoading(true)

        const brachLineCategoriesURL = '/branch-line-categories'
        const brachLinesURL = '/branch-lines'

        const dataBrachLineCategories = api.get(brachLineCategoriesURL)
        const dataBrachLines = api.get(brachLinesURL)

        axios
            .all([dataBrachLineCategories, dataBrachLines])
            .then(
                axios.spread((...responses) => {
                    setBranchCategories(responses[0].data)
                    setBranchLines(responses[1].data)
                    setLoading(false)
                })
            )
            .catch((err) => {
                console.error(
                    'Ops! Houve um erro ao realizar as requisições: ' + err
                )
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <section className={`content ${style.dashboard}`}>
            <TopBar />
            <Spacer size={90} />
            <Container>
                <Row>
                    <Col lg={3}>
                        <VerticalNavigation />
                    </Col>
                    <Col lg={9}>
                        <Row>
                            <Col>
                                <h2 className="title blue">
                                    Contatos Internos
                                </h2>
                            </Col>
                        </Row>
                        <Row>
                            {loading && <Loader />}
                            {branchLines.length === 0 && loading === false && (
                                <Card
                                    title="Nenhum ramal encontrado!"
                                    titleGray
                                />
                            )}

                            {branchCategories.map(({ id, title }) => {
                                return (
                                    <Col
                                        lg={
                                            title ===
                                            'Questões Gerais e Compliance'
                                                ? 12
                                                : 4
                                        }
                                        key={id}
                                    >
                                        <Card title={title}>
                                            <div
                                                className={`align-items-start flex-wrap ${
                                                    title !==
                                                    'Questões Gerais e Compliance'
                                                        ? 'flex-col'
                                                        : 'row'
                                                }`}
                                            >
                                                {branchLines
                                                    .filter(
                                                        (branch) =>
                                                            branch.branch_line_category_id ===
                                                            id
                                                    )
                                                    .map(
                                                        ({
                                                            id,
                                                            title,
                                                            info,
                                                            link,
                                                        }) => (
                                                            <UsersEmailPhoneBox
                                                                key={id}
                                                                name={title}
                                                                info={info}
                                                                link={link}
                                                            />
                                                        )
                                                    )}
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Footer colorBlue={false} />
        </section>
    )
}

export default Branches
