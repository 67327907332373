import React from 'react'
import { useNavigate } from 'react-router-dom'

import Card from '../Card'

import moment from 'moment'
import 'moment/locale/pt-br'

import api from '../../api'

import style from './style.module.scss'

function Schedules() {
    const navigate = useNavigate()

    const [schedules, setSchedules] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    const [events, setEvents] = React.useState([])

    /* Request booking of author authenticated
    -------------------------------------- */
    function getAllBookingByAuthor(id) {
        let query = `?author=${id}&start_date:init=${moment
            .parseZone(new Date())
            .utc(true)
            .format('YYYY-MM-DD')} 08:00`

        api.get(`/events/bookings${query}`)
            .then((response) => {
                if (response && response.status === 200) {
                    const { data } = response
                    setIsLoading(false)
                    setSchedules(data)
                }
            })
            .catch((error) => {
                console.error('#Error:', error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    function getEvents() {
        api.get('/events')
            .then((response) => {
                if (response.data || response.status === 200) {
                    setEvents(response.data)
                }
            })
            .catch((err) => console.log(err))
    }

    async function deleteBooking(id) {
        let confirm = window.confirm('Deseja excluir o agendamento?')

        if (confirm) {
            await api.delete('/events/bookings/' + id)
            return (window.location.href =
                window.location.protocol +
                '//' +
                window.location.host +
                '/app/agenda')
        }
    }

    React.useEffect(() => {
        getEvents()
        const authMe = JSON.parse(localStorage.getItem('me'))
        const { id } = authMe

        if (!id || id !== JSON.parse(localStorage.getItem('me')).id) return

        getAllBookingByAuthor(id)
    }, [])

    /* Order by ASC
    ----------------------------------------- */
    const schedulesFilter = schedules.sort(function (a, b) {
        return new Date(a.start_at) - new Date(b.start_at)
    })

    let today = moment(new Date()).locale('pt-br').format('L')
    let newMap = new Map()

    if (events.length !== 0) {
        for (let event of events) {
            const { id } = event
            newMap.set(id, { ...event })
        }
    }

    return (
        <Card title="Agendamentos">
            {isLoading && <span className={style.loading}></span>}
            {schedules.length === 0 && !isLoading && (
                <p>Nenhum registro encontrado.</p>
            )}
            <ul className="list">
                {schedulesFilter?.map((schedule) => (
                    <li
                        key={schedule.id}
                        className={
                            today ===
                            moment(schedule.start_at)
                                .locale('pt-br')
                                .format('L')
                                ? 'today'
                                : 'date'
                        }
                    >
                        <span className="date">
                            {moment(schedule.start_at)
                                .locale('pt-br')
                                .format('DD/MM')}
                        </span>

                        <div className="title">
                            <span>
                                {moment(schedule.start_at)
                                    .locale('pt-br')
                                    .format('HH:mm') + 'h'}
                            </span>
                            <strong>
                                {newMap && newMap.get(schedule.event_id)?.title}
                            </strong>
                        </div>

                        <button
                            className="btn btn-danger btn-sm ms-auto"
                            onClick={() => deleteBooking(schedule.id)}
                        >
                            excluir
                        </button>
                    </li>
                ))}
            </ul>
        </Card>
    )
}

export default Schedules
