import React from 'react'
import { Container, Header, Content, IconButton, Drawer, Modal } from 'rsuite'
import { Plus, Trash, Edit } from '@rsuite/icons'

import TableComponent from '../../components/Table'
import DrawerForm from './DrawerForm'

import api from '../../../api'

function Contacts({ title }) {
    const initialState = {
        behavior: '',
        drawer: false,
        modal: false,
    }
    const [action, setAction] = React.useState({ ...initialState })
    const [data, setData] = React.useState([])

    function getAll(url) {
        api.get(url)
            .then((response) => {
                if (response.data && response.status === 200) {
                    setData(response.data)
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    function _delete(id) {
        api.delete(`/contacts/${id}`).then((response) => {
            console.log(response)
        })
    }

    React.useEffect(() => {
        getAll('/contacts')
    }, [])

    React.useEffect(() => {
        getAll('/contacts')
    }, [action])

    return (
        <Container>
            <Content>
                <Header className="d-flex justify-content-between align-items-center mb-3">
                    <h2 style={{ fontSize: '1rem' }} className="title mb-0">
                        {title}
                    </h2>
                    <IconButton
                        appearance="primary"
                        size="md"
                        icon={<Plus />}
                        onClick={() =>
                            setAction({
                                ...action,
                                behavior: 'create',
                                drawer: true,
                                data: data,
                            })
                        }
                    >
                        Adicionar
                    </IconButton>
                </Header>
                <TableComponent
                    data={data}
                    config={[
                        {
                            label: 'id',
                            key: 'id',
                            width: 50,
                            fixed: true,
                        },
                        {
                            label: 'Nome',
                            key: 'name',
                            fixed: true,
                        },
                        {
                            label: 'E-mail',
                            key: 'email',
                            fixed: true,
                        },
                        {
                            label: 'Cidade',
                            key: 'city_name',
                            fixed: true,
                        },
                    ]}
                    update={(data) => (
                        <IconButton
                            icon={<Edit />}
                            size="xs"
                            color="blue"
                            appearance="primary"
                            onClick={() => {
                                setAction({
                                    ...action,
                                    behavior: 'update',
                                    drawer: true,
                                    data: data,
                                })
                            }}
                        >
                            Editar
                        </IconButton>
                    )}
                    destroy={(data) => (
                        <IconButton
                            icon={<Trash />}
                            size="xs"
                            color="red"
                            appearance="primary"
                            onClick={() => {
                                setAction({
                                    ...action,
                                    behavior: 'delete',
                                    drawer: false,
                                    modal: true,
                                    data: data,
                                })
                                _delete(data.id)
                            }}
                        >
                            Delete
                        </IconButton>
                    )}
                />

                <Drawer
                    open={action.drawer}
                    onClose={() => setAction({ ...action, drawer: false })}
                >
                    <Drawer.Header>
                        <Drawer.Title>{title}</Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                        <DrawerForm action={action} setAction={setAction} />
                    </Drawer.Body>
                </Drawer>

                {action.behavior !== '' && action.behavior === 'delete' && (
                    <Modal open={action.modal}>
                        <Modal.Body>Excluindo com sucesso!</Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    setAction({
                                        ...action,
                                        behavior: '',
                                        drawer: false,
                                        modal: false,
                                    })
                                }}
                            >
                                Ok
                            </button>
                        </Modal.Footer>
                    </Modal>
                )}
            </Content>
        </Container>
    )
}

export default Contacts
