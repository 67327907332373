import { authHeader } from '../_helpers'
import { API_URL } from '../_utils'

export const userService = {
    login,
    logout,
    update,
    delete: _delete,
}

function authMe() {
    return fetch(`${API_URL}/auth/me`, {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    })
        .then((response) => response.json())
        .then((data) => {
            const { role, ...rest } = data
            localStorage.setItem('me', JSON.stringify(rest))
        })
}

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
    }

    return fetch(`${API_URL}/auth/login`, requestOptions)
        .then(handleResponse)
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user))
            return authMe().then(() => user)
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user')
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    }

    return fetch(`/users/${user.id}`, requestOptions).then(handleResponse)
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    }

    return fetch(`${API_URL}/users/${id}`, requestOptions).then(handleResponse)
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text)
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout()
                // eslint-disable-next-line no-restricted-globals
                location.reload(true)
            }

            const error = (data && data.message) || response.statusText
            return Promise.reject(error)
        }

        return data
    })
}
