import React from 'react'

import style from './style.module.scss'

const Footer = ({colorBlue}) => {
  return (
    <footer className={`${style.footer} ${colorBlue ? `${style.isBlue} mt-auto` : ''}`}>
      <p>BFBM Copyright. Todos os direitos reservados.</p>
    </footer>
  )
}

export default Footer