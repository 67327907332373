import React from 'react'
import parse from 'html-react-parser'

import Card from '../Card'
import Loader from '../Loader'

import api from '../../api'
import useFetch from '../../admin/hook/useFetch'

import style from './style.module.scss'

function WarningList() {
    const { data, isLoading, isError } = useFetch('/notices')

    return (
        <section className={style.warning}>
            {isLoading && <Loader />}

            {isLoading && data.length === 0 && null}
            {!isLoading && data.length !== 0 && (
                <>
                    <h2 className="title">Aviso</h2>
                    {data.map(({ id, title, content, color_hex }) => (
                        <Card
                            key={id}
                            backgroundColor={color_hex}
                            title={title}
                        >
                            {parse(content)}
                        </Card>
                    ))}
                </>
            )}
        </section>
    )
}

export default WarningList
