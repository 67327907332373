import axios from "axios";
import { API_URL } from "./_utils"
import { authHeader } from './_helpers';

const api = axios.create({
    baseURL: API_URL,
    headers: authHeader()
});

export default api;
