import React, { useState, useEffect } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import Loader from '../Loader'
import { Button } from 'rsuite'
// import Button from "../Button";

import api from '../../api'

import style from './style.module.scss'

const SearchBfbmEducation = ({ categories, files }) => {
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [dataFiles, setDataFiles] = useState([])
    const [dataFilesCategories, setDataFilesCategories] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [searchParam] = useState(['title', 'file'])
    const [filterParam, setFilterParam] = useState('All')

    useEffect(() => {
        setDataFiles(files)
        setDataFilesCategories(categories)
        if (dataFiles !== []) {
            setError(false)
            setIsLoaded(true)
        }
    }, [dataFiles, categories, files])

    function search(items) {
        // eslint-disable-next-line array-callback-return
        return items.filter((item) => {
            const academicCategoriesId = item.academic_categories_id.toString()
            if (filterParam === academicCategoriesId) {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) > -1
                    )
                })
            } else if (filterParam === 'All') {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) > -1
                    )
                })
            }
        })
    }

    const handleDownload = (id) => {
        api.get(`/attachments/download/${id}`, { responseType: 'blob' })
            .then((response) => {
                const blob = response.data
                const contentType = response.headers['content-type']
                const contentDisposition =
                    response.headers['content-disposition']

                let filename = ''

                if (
                    contentDisposition &&
                    contentDisposition.indexOf('attachment') !== -1
                ) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                    let matches = filenameRegex.exec(contentDisposition)
                    if (matches != null && matches[1])
                        filename = matches[1].replace(/['"]/g, '')
                }

                const file = new Blob([blob], { type: contentType })
                const fileURL = URL.createObjectURL(file)

                if (filename) {
                    let linkDownload = document.createElement('a')

                    linkDownload.setAttribute('href', fileURL)
                    linkDownload.setAttribute('download', filename)

                    linkDownload.click()
                }
            })
            .catch((error) => console.log(error))
    }

    if (error) {
        return (
            <Alert variant="danger">
                Não foi possível se conectar com a plataforma.
            </Alert>
        )
    } else if (!isLoaded) {
        return (
            <div className="loadingSpinner">
                <Loader />
            </div>
        )
    } else {
        return (
            <>
                <Row>
                    <Col lg="8">
                        <input
                            type="search"
                            name="search-form"
                            id="search-form"
                            className={`form-control ${style.customInput}`}
                            placeholder="Pesquisar"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                    </Col>

                    <Col lg="4">
                        <select
                            onChange={(e) => {
                                setFilterParam(e.target.value)
                            }}
                            className={`form-control ${style.customSelect}`}
                        >
                            <option value="All">Filtrar por tipo</option>
                            {dataFilesCategories.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.title}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                <div className={style.results}>
                    {inputValue !== '' || filterParam !== 'All' ? (
                        <ul className={style['card-grid']}>
                            {search(dataFiles).map((item, index) => (
                                <li key={index}>
                                    <Button
                                        key={item.id}
                                        size="lg"
                                        appearance="ghost"
                                        className="button-download w-100 py-3 mb-3"
                                        title={item.title}
                                        onClick={() => {
                                            handleDownload(item.attachment_id)
                                        }}
                                    >
                                        <svg
                                            width="18"
                                            height="17"
                                            viewBox="0 0 18 17"
                                            fill="#739bc4"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M7.01247 3.49625V0.806309C6.99969 0.714284 7.00518 0.620642 7.02864 0.530744C7.0521 0.440846 7.09305 0.356458 7.14916 0.28241C7.20528 0.208362 7.27545 0.146107 7.35565 0.0992125C7.43586 0.0523184 7.52453 0.021704 7.61658 0.00912258C7.67922 0.00107223 7.74263 0.00107223 7.80527 0.00912258C8.59221 0.00912258 9.37916 0.00912258 10.1661 0.00912258C10.2597 -0.00590128 10.3553 -0.00234038 10.4474 0.0195993C10.5396 0.041539 10.6265 0.0814263 10.7033 0.136986C10.78 0.192545 10.8451 0.262688 10.8947 0.3434C10.9444 0.424112 10.9776 0.513812 10.9925 0.607376C11.0042 0.682987 11.0042 0.759949 10.9925 0.83556V6.13208C10.9925 6.54457 10.9925 6.54603 11.4182 6.54603H13.7249C13.8822 6.5243 14.0423 6.55436 14.181 6.63167C14.3197 6.70897 14.4295 6.82932 14.4938 6.97454C14.5581 7.11975 14.5733 7.28194 14.5372 7.43659C14.5012 7.59124 14.4158 7.72995 14.2939 7.83177C12.7463 9.57631 11.2007 11.3233 9.65708 13.0727C9.59377 13.1881 9.50086 13.2846 9.38792 13.3523C9.27497 13.4199 9.14605 13.4563 9.0144 13.4576C8.88276 13.459 8.75312 13.4253 8.63882 13.3599C8.52452 13.2946 8.42968 13.2 8.36403 13.0859C6.92373 11.4652 5.48782 9.84106 4.0563 8.21354L3.60286 7.69866C3.51407 7.60407 3.45616 7.48471 3.43683 7.35643C3.41749 7.22815 3.43765 7.09702 3.49462 6.98046C3.54519 6.85077 3.63457 6.73986 3.75056 6.66289C3.86654 6.58592 4.00346 6.54664 4.14261 6.55042C4.8374 6.54311 5.53366 6.55042 6.22845 6.55042C7.00955 6.55042 7.00954 6.55042 7.00954 5.7781L7.01247 3.49625Z"
                                                fill="inherit"
                                            />
                                            <path
                                                d="M9.01623 14.6744H16.8125C16.9818 14.6618 17.1517 14.6861 17.3106 14.7457C17.4694 14.8053 17.6134 14.8988 17.7326 15.0196C17.913 15.2138 18.0089 15.4717 17.9993 15.7367C17.9897 16.0016 17.8754 16.2519 17.6814 16.4326C17.557 16.547 17.4063 16.629 17.2426 16.671C17.0907 16.7038 16.9357 16.72 16.7804 16.7193H1.23747C1.10007 16.7191 0.962977 16.7063 0.827897 16.6812C0.600811 16.6393 0.394889 16.521 0.244299 16.3459C0.093709 16.1709 0.00750834 15.9496 0 15.7188C0.00113162 15.4933 0.0759166 15.2744 0.212963 15.0954C0.350009 14.9163 0.541807 14.787 0.759154 14.727C0.921576 14.6891 1.08832 14.6728 1.25502 14.6788L9.01623 14.6744Z"
                                                fill="inherit"
                                            />
                                        </svg>
                                        <span className="d-block ml-2">
                                            {item.title}
                                        </span>
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>Seus resultados aparecerão aqui</p>
                    )}
                </div>
            </>
        )
    }
}

export default SearchBfbmEducation
