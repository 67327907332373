import React from 'react'

import { Nav } from 'rsuite'
import DashboardIcon from '@rsuite/icons/legacy/Dashboard'
import PeoplesIcon from '@rsuite/icons/Peoples'
import SearchIcon from '@rsuite/icons/Search'
import GearIcon from '@rsuite/icons/Gear'
import AdminIcon from '@rsuite/icons/Admin'
import WaitIcon from '@rsuite/icons/Wait'
import StorageIcon from '@rsuite/icons/Storage'
import PhoneIcon from '@rsuite/icons/Phone'
import PageIcon from '@rsuite/icons/Page'
import PeopleBranchIcon from '@rsuite/icons/PeopleBranch'
import TextImageIcon from '@rsuite/icons/TextImage'
import UserInfoIcon from '@rsuite/icons/UserInfo'
import NoticeIcon from '@rsuite/icons/Notice'

function Sidebar({ active, setActive }) {
    function handleOnSelect(event) {
        switch (event) {
            case 'dashboard':
                setActive('Dashboard')
                break
            case 'comites':
                setActive('Comitês')
                break
            case 'bfbm-educacao':
                setActive('BFBM Educação')
                break
            case 'bfbm-academico':
                setActive('BFBM Acadêmico')
                break
            case 'bfbm-academico-categoria':
                setActive('BFBM Acadêmico - Categoria')
                break
            case 'bfbm-academico-arquivo':
                setActive('BFBM Acadêmico - Arquivo')
                break
            case 'ramais-uteis':
                setActive('Ramais Úteis')
                break
            case 'ramais-uteis-categoria':
                setActive('Ramais Úteis - Categoria')
                break
            case 'comunicao':
                setActive('Comunicação')
                break
            case 'comunicacao-link':
                setActive('Comunicação - Link')
                break
            case 'contencioso-e-pesquisa':
                setActive('Contencioso e Pesquisa')
                break
            case 'contencioso-e-pesquisa-categoria':
                setActive('Contencioso e Pesquisa - Categoria')
                break
            case 'solicitacao-de-viagens':
                setActive('Solicitação de Viagens')
                break
            case 'solicitacao-de-viagens-link':
                setActive('Solicitação de Viagens - Link')
                break
            case 'contatos':
                setActive('Contatos')
                break
            case 'manuais':
                setActive('Manuais')
                break
            case 'manuais-categoria':
                setActive('Manuais - Categoria')
                break
            case 'gente':
                setActive('Gente')
                break
            case 'gente-links':
                setActive('Gente - Links')
                break
            case 'gente-equipes':
                setActive('Gente - Equipes')
                break
            case 'gente-clientes':
                setActive('Gente - Clientes')
                break
            case 'servicos':
                setActive('Serviços')
                break
            case 'servicos-categoria':
                setActive('Serviços - Categoria')
                break
            case 'usuarios':
                setActive('Usuários')
                break
            case 'palestras':
                setActive('Palestras')
                break
            case 'agendar-palestra':
                setActive('Agendar Palestra')
                break
            case 'avisos':
                setActive('Avisos')
                break
            default:
                setActive('Dashboard')
                break
        }
    }

    return (
        <div className="col-lg-3 d-none d-lg-block">
            <Nav
                vertical
                activeKey={active}
                onSelect={(event) => handleOnSelect(event)}
            >
                <Nav.Item
                    eventKey="dashboard"
                    style={{ fontWeight: 'bold', color: '#666' }}
                    icon={<DashboardIcon />}
                    active={active === 'dashboard'}
                >
                    Dashboard
                </Nav.Item>

                <Nav.Item
                    eventKey="comites"
                    style={{ fontWeight: 'bold', color: '#666' }}
                    icon={<PeopleBranchIcon />}
                    active={active === 'comites'}
                >
                    Comitês
                </Nav.Item>

                <Nav.Item
                    eventKey="palestras"
                    style={{ fontWeight: 'bold', color: '#666' }}
                    icon={<PageIcon />}
                    active={active === 'palestras'}
                >
                    Palestras
                </Nav.Item>
                <Nav.Item
                    style={{ paddingLeft: '2.75rem', fontSize: '.85rem' }}
                    eventKey="agendar-palestra"
                    active={active === 'agendar-palestra'}
                >
                    Agendar Palestra
                </Nav.Item>

                <Nav.Item
                    eventKey="bfbm-educacao"
                    style={{ fontWeight: 'bold', color: '#666' }}
                    icon={<PageIcon />}
                    active={active === 'bfbm-educacao'}
                >
                    BFBM Educação
                </Nav.Item>
                <Nav.Item
                    eventKey="bfbm-academico"
                    style={{ fontWeight: 'bold', color: '#666' }}
                    icon={<PageIcon />}
                    active={active === 'bfbm-academico'}
                >
                    BFBM Acadêmico
                </Nav.Item>

                <Nav.Item
                    style={{ paddingLeft: '2.75rem', fontSize: '.85rem' }}
                    eventKey="bfbm-academico-categoria"
                    active={active === 'bfbm-academico-categoria'}
                >
                    Categoria
                </Nav.Item>

                <Nav.Item
                    style={{ paddingLeft: '2.75rem', fontSize: '.85rem' }}
                    eventKey="bfbm-academico-arquivo"
                    active={active === 'bfbm-academico-arquivo'}
                >
                    Arquivo
                </Nav.Item>

                <Nav.Item
                    eventKey="ramais-uteis"
                    style={{ fontWeight: 'bold', color: '#666' }}
                    icon={<PhoneIcon />}
                    active={active === 'ramais-uteis'}
                >
                    Ramais Úteis
                </Nav.Item>

                <Nav.Item
                    style={{ paddingLeft: '2.75rem', fontSize: '.85rem' }}
                    eventKey="ramais-uteis-categoria"
                    active={active === 'ramais-uteis-categoria'}
                >
                    Categoria
                </Nav.Item>

                <Nav.Item
                    eventKey="comunicao"
                    style={{ fontWeight: 'bold', color: '#666' }}
                    icon={<StorageIcon />}
                    active={active === 'comunicao'}
                >
                    Comunicação
                </Nav.Item>
                <Nav.Item
                    style={{ paddingLeft: '2.75rem', fontSize: '.85rem' }}
                    eventKey="comunicacao-link"
                    active={active === 'comunicacao-link'}
                >
                    Links
                </Nav.Item>

                <Nav.Item
                    eventKey="solicitacao-de-viagens"
                    style={{ fontWeight: 'bold', color: '#666' }}
                    icon={<WaitIcon />}
                    active={active === 'solicitacao-de-viagens'}
                >
                    Solicitação de Viagens
                </Nav.Item>
                <Nav.Item
                    style={{ paddingLeft: '2.75rem', fontSize: '.85rem' }}
                    eventKey="solicitacao-de-viagens-link"
                    active={active === 'solicitacao-de-viagens-link'}
                >
                    Link
                </Nav.Item>
                <Nav.Item
                    eventKey="contatos"
                    style={{ paddingLeft: '2.75rem', fontSize: '.85rem' }}
                    active={active === 'contatos'}
                >
                    Contatos
                </Nav.Item>

                <Nav.Item
                    eventKey="manuais"
                    style={{ fontWeight: 'bold', color: '#666' }}
                    icon={<TextImageIcon />}
                    active={active === 'manuais'}
                >
                    Manuais
                </Nav.Item>
                <Nav.Item
                    style={{ paddingLeft: '2.75rem', fontSize: '.85rem' }}
                    eventKey="manuais-categoria"
                    active={active === 'manuais-categoria'}
                >
                    Categoria
                </Nav.Item>

                <Nav.Item
                    eventKey="contencioso-e-pesquisa"
                    style={{ fontWeight: 'bold', color: '#666' }}
                    icon={<SearchIcon />}
                    active={active === 'contencioso-e-pesquisa'}
                >
                    Contencioso e Pesquisa
                </Nav.Item>
                <Nav.Item
                    style={{ paddingLeft: '2.75rem', fontSize: '.85rem' }}
                    eventKey="contencioso-e-pesquisa-categoria"
                    active={active === 'contencioso-e-pesquisa-categoria'}
                >
                    Categoria
                </Nav.Item>
                <Nav.Item
                    eventKey="gente"
                    style={{ fontWeight: 'bold', color: '#666' }}
                    icon={<PeoplesIcon />}
                    active={active === 'gente'}
                >
                    Gente
                </Nav.Item>
                <Nav.Item
                    style={{ paddingLeft: '2.75rem', fontSize: '.85rem' }}
                    eventKey="gente-equipes"
                    active={active === 'gente-equipes'}
                >
                    Equipe
                </Nav.Item>
                <Nav.Item
                    style={{ paddingLeft: '2.75rem', fontSize: '.85rem' }}
                    eventKey="gente-clientes"
                    active={active === 'gente-clientes'}
                >
                    Cliente
                </Nav.Item>
                <Nav.Item
                    style={{ paddingLeft: '2.75rem', fontSize: '.85rem' }}
                    eventKey="gente-links"
                    active={active === 'gente-links'}
                >
                    Link
                </Nav.Item>

                <Nav.Item
                    eventKey="servicos"
                    style={{ fontWeight: 'bold', color: '#666' }}
                    icon={<GearIcon />}
                    active={active === 'servicos'}
                >
                    Serviços
                </Nav.Item>
                <Nav.Item
                    style={{ paddingLeft: '2.75rem', fontSize: '.85rem' }}
                    eventKey="servicos-categoria"
                    active={active === 'servicos-categoria'}
                >
                    Categoria
                </Nav.Item>
                <Nav.Item
                    style={{ fontWeight: 'bold', color: '#666' }}
                    eventKey="usuarios"
                    icon={<AdminIcon />}
                    active={active === 'usuarios'}
                >
                    Usuários
                </Nav.Item>
                <Nav.Item
                    style={{ fontWeight: 'bold', color: '#666' }}
                    eventKey="avisos"
                    icon={<NoticeIcon />}
                    active={active === 'avisos'}
                >
                    Avisos
                </Nav.Item>
            </Nav>
        </div>
    )
}

export default Sidebar
