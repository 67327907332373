import React from 'react'
import { Col, Container, Form, Navbar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import VerticalNavigation from '../VerticalNavigation'
import { useDispatch } from 'react-redux'
import { userActions } from '../../_redux/actions'
import routes from '../../_resources/routes.json'

import api from '../../api'

import style from './style.module.scss'

import logoBFBM from '../../assets/images/logo-bfbm.svg'
import profileDefault from '../../assets/images/profile_default.png'

const TopBar = () => {
    /* Auth Me 
    -------------------------- */
    const [data, setData] = React.useState('')

    function getAuthMe() {
        api.get('/auth/me')
            .then((response) => {
                if (response.data && response.status === 200) {
                    setData(response.data)
                }
            })
            .catch((error) => {
                console.log('ERROR:', error)
            })
    }

    React.useEffect(() => {
        getAuthMe()
    }, [])

    const dispatch = useDispatch()

    const getLogout = () => {
        dispatch(userActions.logout())
        window.location.assign(routes.HOME_PAGE)
        localStorage.removeItem('me')
    }

    return (
        <section className={style.topbar}>
            <div className={style.desk}>
                <Container>
                    <Row className="align-items-center">
                        <Col lg={4}>
                            <div className={style.logoProfile}>
                                <Link to="/app/dashboard">
                                    <img
                                        src={logoBFBM}
                                        alt="Logotipo BFBM"
                                        className={style.logo}
                                    />
                                </Link>

                                {!data ? (
                                    <small className="text-white p-2">
                                        Loading...
                                    </small>
                                ) : null}

                                {data && (
                                    /* Profile */
                                    <Link
                                        to="/app/perfil"
                                        className={style.profile}
                                    >
                                        {data.avatar ? (
                                            <img
                                                src={data?.avatar}
                                                style={{
                                                    width: '48px',
                                                    height: '48px',
                                                    objectFit: 'cover',
                                                }}
                                                alt={data.name}
                                                className={`${style.photo} rounded-circle`}
                                            />
                                        ) : (
                                            <img
                                                src={profileDefault}
                                                style={{
                                                    width: '48px',
                                                    height: '48px',
                                                    objectFit: 'cover',
                                                }}
                                                alt={data.name}
                                                className={`${style.photo} rounded-circle`}
                                            />
                                        )}

                                        <div className="info">
                                            <span className={style.name}>
                                                {data.name}
                                                {/* Eduardo Santos */}
                                            </span>
                                            <span className={style.role}>
                                                {data.occupation ?? ''} |{' '}
                                                {data.state_uf ?? ''}
                                                {/* Designer | RJ */}
                                            </span>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </Col>
                        <Col lg={4}>
                            {/* <div className={style.searchBox}>
                                <Form>
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            placeholder="Pesquisar"
                                            className={style.inputSearch}
                                        />
                                    </Form.Group>
                                </Form>
                            </div> */}
                        </Col>
                        <Col
                            lg={{ span: 2, offset: 2 }}
                            className={style.buttons}
                        >
                            <Link
                                to="/app/agenda"
                                className="btn btn-outline-light me-2"
                            >
                                Reservas
                            </Link>

                            <span
                                className="btn text-light"
                                onClick={getLogout}
                            >
                                Logout
                            </span>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Navbar expand="lg" className={style.mobile}>
                <Container>
                    <Navbar.Brand>
                        <Link to="/app/dashboard">
                            <img
                                src={logoBFBM}
                                alt="Logotipo BFBM"
                                className={style.logo}
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <VerticalNavigation isTopBar />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </section>
    )
}

export default TopBar
