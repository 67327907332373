import React from 'react'
import Loader from '../Loader'

import moment from 'moment'
import 'moment/locale/pt-br'

import useFetch from '../../admin/hook/useFetch'

import style from './style.module.scss'

import profile from '../../assets/images/profile.png'

const BirthdayBox = () => {
    const { isLoading, data: users } = useFetch('/users/birthdays?show=next')
    const currentDate = moment(new Date()).locale('pt_BR').format('DD/MM')

    return (
        <div className={style.birthdayBox}>
            <div className={style.boxHeader}>
                <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.01 18.87V25.7C10.01 26.09 10.01 26.09 9.63 26.09H2.90001C2.29001 26.09 2.04001 25.84 2.04001 25.24V12.04C2.04001 11.65 2.04 11.64 2.44 11.64H9.56001C9.96001 11.64 9.97002 11.64 9.97002 12.07L10.01 18.87Z"
                        fill="#363636"
                    />
                    <path
                        d="M16.79 18.83V12.03C16.79 11.64 16.79 11.64 17.17 11.64H24.35C24.63 11.64 24.73 11.72 24.73 12.01C24.73 16.43 24.73 20.8533 24.73 25.28C24.73 25.84 24.47 26.1 23.9 26.1H17.15C16.86 26.1 16.79 26 16.79 25.73C16.7967 23.43 16.7967 21.13 16.79 18.83Z"
                        fill="#363636"
                    />
                    <path
                        d="M13.43 6.85001H25.9C26.62 6.85001 26.84 7.06999 26.84 7.79999V9.42999C26.84 10.05 26.57 10.33 25.94 10.33H0.960011C0.82508 10.3466 0.688157 10.3338 0.558675 10.2923C0.429192 10.2509 0.310232 10.1819 0.210011 10.09C0.06719 9.93136 -0.00813758 9.72328 1.98485e-05 9.50998V7.75C1.98485e-05 7.07 0.240018 6.82999 0.920018 6.82999L13.43 6.85001Z"
                        fill="#363636"
                    />
                    <path
                        d="M15.49 18.88V25.71C15.49 26.09 15.49 26.09 15.11 26.09C13.95 26.09 12.8 26.09 11.65 26.09C11.39 26.09 11.3 26 11.3 25.74C11.3 21.1533 11.3 16.5633 11.3 11.97C11.3 11.7 11.39 11.62 11.65 11.62H15.14C15.41 11.62 15.5 11.71 15.5 11.98C15.4867 14.28 15.4833 16.58 15.49 18.88Z"
                        fill="#363636"
                    />
                    <path
                        d="M12.7301 3.73999C12.7301 4.31999 12.7301 4.78998 12.7301 5.25997C12.7301 5.46997 12.6501 5.55998 12.4401 5.54998C11.5001 5.54998 10.5601 5.59998 9.6201 5.54998C9.11457 5.48644 8.63636 5.2847 8.23807 4.96695C7.83978 4.64919 7.53686 4.22772 7.36261 3.74893C7.18836 3.27014 7.14954 2.75256 7.25043 2.25314C7.35132 1.75371 7.588 1.29179 7.93444 0.918178C8.28087 0.544569 8.72364 0.273763 9.21404 0.135524C9.70444 -0.00271397 10.2235 -0.0030294 10.714 0.134639C11.2046 0.272308 11.6477 0.542622 11.9946 0.915828C12.3414 1.28903 12.5786 1.75069 12.6801 2.25C12.7347 2.74467 12.7514 3.24277 12.7301 3.73999Z"
                        fill="#363636"
                    />
                    <path
                        d="M15.7501 5.54941H14.3401C14.1401 5.54941 14.0601 5.46941 14.0701 5.26941C14.0701 4.33941 14.0701 3.4094 14.0701 2.4794C14.1273 1.97677 14.3221 1.49968 14.6331 1.10062C14.944 0.701555 15.3589 0.396011 15.8323 0.21756C16.3057 0.0391084 16.8191 -0.00532436 17.3161 0.0891723C17.8131 0.183669 18.2743 0.413414 18.6492 0.753143C19.024 1.09287 19.2979 1.52942 19.4406 2.01474C19.5834 2.50006 19.5895 3.01536 19.4583 3.50394C19.3271 3.99251 19.0637 4.43543 18.697 4.78397C18.3304 5.1325 17.8747 5.37313 17.3801 5.4794C16.84 5.55203 16.2944 5.57547 15.7501 5.54941Z"
                        fill="#363636"
                    />
                </svg>
                <h3>Aniversários</h3>
            </div>
            <ul className={style.birthdayList}>
                {isLoading && <Loader />}
                {!isLoading && users.length === 0 && (
                    <small className="col-12 text-center">
                        Nenhum registro encontrado.
                    </small>
                )}
                {users.map((user) => (
                    <li
                        key={user.id}
                        className={
                            moment(user.birthday)
                                .locale('pt_BR')
                                .format('DD/MM') === currentDate
                                ? style.today
                                : ''
                        }
                    >
                        <img
                            src={user.avatar}
                            alt={user.name}
                            className={`mb-2 ${style.photo}`}
                            title={moment(user.birthday)
                                .locale('pt-br')
                                .format('DD/MM/YYYY')}
                        />
                        <span className={style.name}>
                            {user.name}
                            <br />
                            <strong>
                                {moment(user.birthday)
                                    .locale('pt-br')
                                    .format('DD/MM')}
                            </strong>
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default BirthdayBox
