import React, { useState, useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {Col, Container, Row} from 'react-bootstrap'
import Footer from '../components/Footer'
import { userActions } from '../_redux/actions';

import Route from '../_resources/routes.json'

import style from '../scss/pages/login.module.scss'

import logoBFBM from '../assets/images/logo-bfbm.svg'

const Login = () => {
    const [inputs, setInputs] = useState({
        email: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { email, password } = inputs;
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    // reset login status
    useEffect(() => {
        dispatch(userActions.logout());
    }, [dispatch]);


    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (email && password) {
            // get return url from location state or default to home page
            const { from } = location.state || { from: { pathname: Route.DASHBOARD_PAGE } };
            dispatch(userActions.login(email, password, from));
            navigate(Route.DASHBOARD_PAGE)
        }
    }

    return (
        <section className={`page d-flex flex-column ${style.login}`}>
            <Container>
                <Row>
                    <Col md={{span: 4, offset: 4}}>
                        <img src={logoBFBM} alt="Logotipo BFBM" className={style.logo}/>
                        <form name="form" onSubmit={handleSubmit} className={style.form} >
                            <div className="form-group mb-3">
                                <label>Email</label>
                                <input type="text" name="email" value={email} onChange={handleChange} className={'form-control' + (submitted && !email ? ' is-invalid' : '')} />
                                {submitted && !email &&
                                    <div className="invalid-feedback">Email é obrigatório</div>
                                }
                            </div>
                            <div className="form-group mb-3">
                                <label>Senha</label>
                                <input type="password" name="password" value={password} onChange={handleChange} className={'form-control' + (submitted && !password ? ' is-invalid' : '')} />
                                {submitted && !password &&
                                    <div className="invalid-feedback">Senha é obrigatório</div>
                                }
                            </div>
                            <div className="form-group mb-3">
                                <button className="btn btn-primary">
                                    Entrar
                                </button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
            <Footer colorBlue={true}/>
        </section>
    )
}

export default Login