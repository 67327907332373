import React from 'react'
import Loader from '../Loader'

import moment from 'moment'
import 'moment/locale/pt-br'

import useFetch from '../../admin/hook/useFetch'

import style from './style.module.scss'

const LecturesEvents = () => {
    let query = `?event_type=lecture&start_date:init=${moment(new Date())
        .subtract(1, 'day')
        .locale('pt-br')
        .format('YYYY-MM-DD')} 08:00:00&start_date:final=${moment(new Date())
        .endOf('month')
        .locale('pt-br')
        .format('YYYY-MM-DD')} 23:00:00`

    const today = moment(new Date()).locale('pt-br').format('L')
    const { isLoading, data: lectures } = useFetch(`/events/schedules${query}`)

    return (
        <div className={style.lecturesEvents}>
            <div className={style.boxHeader}>
                <svg
                    width="34"
                    height="33"
                    viewBox="0 0 34 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M17.0038 22.9874H32.2318C32.9918 22.9874 33.1814 23.1789 33.1814 23.9486V26.8014C33.1814 27.385 32.9273 27.6335 32.3375 27.6356C31.3632 27.6356 30.389 27.6486 29.4161 27.6356C29.0947 27.6294 28.9876 27.7242 28.9876 28.064C28.9993 31.1002 28.9876 29.4613 28.9876 32.4988C28.9876 32.9972 28.9876 33 28.496 33H5.49439C5.00622 33 5.00485 33 5.00485 32.4967C5.00485 29.4716 5.01171 31.1235 5.01171 28.097C5.01171 27.6487 5.01171 27.6473 4.55101 27.6466H1.66043C1.07133 27.6466 0.820724 27.3967 0.818665 26.8103V23.8882C0.818665 23.2194 1.03289 23.0011 1.70095 23.0011L17.0038 22.9874Z"
                        fill="#363636"
                    />
                    <path
                        d="M21.698 14.328V20.7615C21.698 21.2167 21.6877 21.2297 21.2414 21.2297C18.3417 21.2297 15.4427 21.2318 12.5443 21.2359C12.2154 21.2359 12.1063 21.1288 12.1097 20.802C12.1214 19.0257 12.1145 17.2495 12.1145 15.4747V15.0627C12.0561 15.1087 12.0225 15.1231 12.0074 15.1472C11.511 16.1771 11.0194 17.2138 10.5182 18.2437C10.4846 18.3158 10.4701 18.3953 10.476 18.4746C10.4819 18.554 10.508 18.6305 10.5518 18.6968C10.9095 19.4267 11.2549 20.1634 11.6222 20.894C11.7451 21.1391 11.7293 21.2372 11.4231 21.2372C10.5065 21.2269 9.5899 21.2372 8.67672 21.2372C8.52773 21.2372 8.36912 21.2489 8.28879 21.0814C7.94961 20.368 7.54657 19.689 7.27262 18.9399C7.20082 18.752 7.16887 18.5512 7.1788 18.3502C7.18873 18.1493 7.24032 17.9526 7.3303 17.7727C8.1398 16.1159 8.86279 14.4139 9.77184 12.8086C10.1066 12.1879 10.5987 11.6661 11.1987 11.2956C11.7987 10.925 12.4855 10.7187 13.1904 10.6973C15.6621 10.5518 18.138 10.6362 20.6111 10.6527C21.3494 10.6419 22.0786 10.8168 22.7316 11.1612C23.3847 11.5056 23.9409 12.0086 24.349 12.6239C24.7451 13.1849 25.1729 13.7225 25.5738 14.282C25.7338 14.5093 25.8485 14.5567 26.0716 14.3507C27.1324 13.3558 28.2124 12.376 29.2835 11.3921C29.4698 11.1875 29.7052 11.0339 29.9675 10.9458C30.2298 10.8577 30.5103 10.8381 30.7824 10.8889C31.0616 10.9366 31.3212 11.0637 31.5301 11.2551C31.7389 11.4466 31.8881 11.6941 31.9599 11.9682C32.0533 12.2301 32.0661 12.5139 31.9967 12.7831C31.9273 13.0524 31.7789 13.2946 31.5706 13.4787C30.4899 14.4873 29.3989 15.4843 28.3134 16.486C27.8252 16.9371 27.3349 17.3855 26.8509 17.8407C26.6871 18.0488 26.4742 18.2129 26.2313 18.3183C25.9884 18.4237 25.7232 18.4671 25.4593 18.4446C25.1955 18.422 24.9414 18.3343 24.7199 18.1892C24.4984 18.0441 24.3164 17.8463 24.1903 17.6134C23.4481 16.6096 22.7128 15.601 21.9726 14.5924C21.9124 14.4766 21.816 14.3838 21.698 14.328Z"
                        fill="#363636"
                    />
                    <path
                        d="M16.9276 8.80021C16.1373 8.8017 15.3644 8.56898 14.7063 8.13142C14.0483 7.69386 13.5347 7.07111 13.2304 6.34181C12.9261 5.61252 12.8447 4.80938 12.9965 4.03387C13.1484 3.25836 13.5267 2.54526 14.0836 1.98461C14.6405 1.42397 15.3511 1.04093 16.1255 0.883882C16.9 0.72684 17.7037 0.802833 18.435 1.10227C19.1663 1.4017 19.7925 1.91114 20.2344 2.56624C20.6764 3.22135 20.9143 3.99273 20.9181 4.78296C20.9207 5.309 20.8195 5.83037 20.6202 6.31721C20.4209 6.80404 20.1275 7.24675 19.7568 7.61996C19.386 7.99317 18.9453 8.28955 18.4598 8.49208C17.9743 8.69462 17.4536 8.79932 16.9276 8.80021Z"
                        fill="#363636"
                    />
                </svg>
                <h3>Palestras e Eventos</h3>
            </div>
            <ul className={style.list}>
                {isLoading && <Loader />}
                {!isLoading && lectures.length === 0 && (
                    <p className="text-center">
                        <small>Nenhum registro encontrado.</small>
                    </p>
                )}
                {lectures.map(({ id, start_at, event_title }) => (
                    <li
                        key={id}
                        className={`${
                            today ===
                            moment(start_at).locale('pt-br').format('L')
                                ? style.today
                                : style.date
                        } align-items-center`}
                    >
                        <span className={style.date}>
                            {`${moment(start_at).format('HH:mm')}h`}
                            <br />
                            {moment(start_at).format('DD/MM')}
                        </span>
                        <span className={style.title}>{event_title}</span>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default LecturesEvents
