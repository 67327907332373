import React from 'react'
import { Link } from 'react-router-dom'
import { Loader } from 'rsuite'

import style from '../../../components/TopBar/style.module.scss'

import profileDefault from '../../../assets/images/profile_default.png'

import api from '../../../api'

/* Profile 
----------------------------- */
function Profile() {
    /* Auth Me 
    -------------------------- */
    const [data, setData] = React.useState('')

    function getAuthMe() {
        api.get('/auth/me')
            .then((response) => {
                if (response.data && response.status === 200) {
                    setData(response.data)
                }
            })
            .catch((error) => {
                console.log('ERROR:', error)
            })
    }

    React.useEffect(() => {
        getAuthMe()
    }, [])

    return (
        <div className={style.logoProfile}>
            {!data ? (
                <small className="text-white p-2">
                    <Loader content="Carregando..." />
                </small>
            ) : null}

            {data && (
                /* Profile */
                <Link
                    to="/app/perfil"
                    className={`${style.profile} text-center text-lg-left mb-4 mb-lg-0`}
                >
                    {data.avatar ? (
                        <img
                            src={data?.avatar}
                            style={{
                                width: '48px',
                                height: '48px',
                                objectFit: 'cover',
                            }}
                            alt={data.name}
                            className={`${style.photo} rounded-circle`}
                        />
                    ) : (
                        <img
                            src={profileDefault}
                            style={{
                                width: '48px',
                                height: '48px',
                                objectFit: 'cover',
                            }}
                            alt={data.name}
                            className={`${style.photo} rounded-circle`}
                        />
                    )}

                    <div className="info">
                        <span className={style.name}>
                            {data.name}
                            {/* Eduardo Santos */}
                        </span>
                        <span
                            className={style.role}
                            style={{ textAlign: 'left' }}
                        >
                            {data.occupation ?? ''} | {data.state_uf ?? ''}
                            {/* Designer | RJ */}
                        </span>
                    </div>
                </Link>
            )}
        </div>
    )
}

export default Profile
