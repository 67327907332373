import React from 'react'
import parse from 'html-react-parser'
import { Col, Container, Row, Tabs, Tab } from 'react-bootstrap'

import TopBar from '../components/TopBar'
import VerticalNavigation from '../components/VerticalNavigation'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'
import Card from '../components/Card'
import Button from '../components/Button'
import Loader from '../components/Loader'

import style from '../scss/pages/dashboard.module.scss'
import UsersRolesBox from '../components/UsersRolesBox'

import api from '../api'

const People = () => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [employeesTabs, setEmployeesTabs] = React.useState([])
    const [employeesBoxes, setEmployeesBoxes] = React.useState([])
    const [employeesClients, setEmployeesClients] = React.useState([])
    const [employeesLinks, setEmployeesLinks] = React.useState([])

    function getAllEmployeesTabs() {
        api.get('/employees/tabs')
            .then((response) => {
                if (response.data && response.status === 200) {
                    setEmployeesTabs(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setIsLoading(true)
            })
    }

    function getAllEmployeesBoxes() {
        api.get('/employees/boxes')
            .then((response) => {
                if (response.data && response.status === 200) {
                    setEmployeesBoxes(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setIsLoading(true)
            })
    }

    function getAllEmployeesClients() {
        api.get('/employees/clients')
            .then((response) => {
                if (response.data && response.status === 200) {
                    setEmployeesClients(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setIsLoading(true)
            })
    }

    function getAllEmployeesLinks() {
        api.get('/employees/links')
            .then((response) => {
                if (response.data && response.status === 200) {
                    setEmployeesLinks(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setIsLoading(true)
            })
    }

    React.useEffect(() => {
        getAllEmployeesTabs()
        getAllEmployeesBoxes()
        getAllEmployeesClients()
        getAllEmployeesLinks()
    }, [])

    return (
        <section className={`content ${style.dashboard}`}>
            <TopBar />
            <Spacer size={90} />
            <Container>
                <Row>
                    <Col lg={3}>
                        <VerticalNavigation />
                    </Col>
                    <Col lg={9}>
                        <Row>
                            <Col>
                                <h2 className="title blue">Gente</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    {!isLoading && <Loader />}
                                    <Tabs
                                        defaultActiveKey="Equipes"
                                        id="uncontrolled-tab"
                                        className="mb-3"
                                    >
                                        {employeesTabs.map((tab) => (
                                            <Tab
                                                key={tab.id}
                                                eventKey={tab.title}
                                                title={tab.title.toLocaleUpperCase()}
                                            >
                                                <Row>
                                                    <Col lg={12}>
                                                        <h3 className="title typeTwo blue">
                                                            {tab.description}
                                                        </h3>
                                                    </Col>
                                                </Row>

                                                {tab.title === 'Equipes' && (
                                                    <>
                                                        <Row className="mb-30">
                                                            {employeesBoxes.length ===
                                                                0 && (
                                                                <p>
                                                                    Nenhum
                                                                    registro
                                                                    encontrado.
                                                                </p>
                                                            )}
                                                            {employeesBoxes &&
                                                                employeesBoxes.map(
                                                                    (
                                                                        boxes,
                                                                        index
                                                                    ) => (
                                                                        <Col
                                                                            key={
                                                                                boxes.id
                                                                            }
                                                                            lg={
                                                                                index <=
                                                                                2
                                                                                    ? 4
                                                                                    : 6
                                                                            }
                                                                            className="mb-30"
                                                                        >
                                                                            <div className="boxBordered">
                                                                                <h4>
                                                                                    {
                                                                                        boxes.title
                                                                                    }
                                                                                </h4>
                                                                                <div
                                                                                    className={
                                                                                        style.usersRolesBox
                                                                                    }
                                                                                >
                                                                                    {parse(
                                                                                        boxes.description
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                )}
                                                        </Row>

                                                        <Row className="mb-30">
                                                            <Col>
                                                                <div className="boxBordered">
                                                                    <h4>
                                                                        Alguns
                                                                        dos
                                                                        nossos
                                                                        clientes
                                                                    </h4>
                                                                    {employeesClients.length ===
                                                                        0 && (
                                                                        <p>
                                                                            Nenhum
                                                                            registro
                                                                            encontrado.
                                                                        </p>
                                                                    )}
                                                                    <div className="imageGrid">
                                                                        {employeesClients &&
                                                                            employeesClients.map(
                                                                                (
                                                                                    client
                                                                                ) => (
                                                                                    <img
                                                                                        key={
                                                                                            client.id
                                                                                        }
                                                                                        src={
                                                                                            client.image
                                                                                        }
                                                                                        alt={
                                                                                            client.title
                                                                                        }
                                                                                    />
                                                                                )
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}

                                                {tab.title === 'Avaliações' && (
                                                    <Row>
                                                        {employeesLinks.filter(
                                                            ({
                                                                employees_id,
                                                            }) =>
                                                                employees_id ===
                                                                tab.id
                                                        ).length === 0 && (
                                                            <p>
                                                                Nenhum registro
                                                                encontrado.
                                                            </p>
                                                        )}
                                                        {employeesLinks &&
                                                            employeesLinks
                                                                .filter(
                                                                    (link) =>
                                                                        link.employees_id ===
                                                                        tab.id
                                                                )
                                                                .map((link) => (
                                                                    <Col
                                                                        key={
                                                                            link.id
                                                                        }
                                                                        lg={6}
                                                                    >
                                                                        <Button
                                                                            url={
                                                                                link.url
                                                                            }
                                                                            target="_blank"
                                                                            text={
                                                                                link.title
                                                                            }
                                                                        />
                                                                    </Col>
                                                                ))}
                                                    </Row>
                                                )}

                                                {tab.title ===
                                                    'Movimentações/Contratações e Desligamentos' && (
                                                    <Row>
                                                        {employeesLinks.filter(
                                                            ({
                                                                employees_id,
                                                            }) =>
                                                                employees_id ===
                                                                tab.id
                                                        ).length === 0 && (
                                                            <p>
                                                                Nenhum registro
                                                                encontrado.
                                                            </p>
                                                        )}
                                                        {employeesLinks &&
                                                            employeesLinks
                                                                .filter(
                                                                    (link) =>
                                                                        link.employees_id ===
                                                                        tab.id
                                                                )
                                                                .map((link) => (
                                                                    <Col
                                                                        key={
                                                                            link.id
                                                                        }
                                                                        lg={6}
                                                                    >
                                                                        <Button
                                                                            url={
                                                                                link.url
                                                                            }
                                                                            target="_blank"
                                                                            text={
                                                                                link.title
                                                                            }
                                                                            typeTwo
                                                                            noIcon
                                                                        />
                                                                    </Col>
                                                                ))}
                                                    </Row>
                                                )}
                                            </Tab>
                                        ))}
                                    </Tabs>

                                    {/* <Tabs
                                        defaultActiveKey="equipes"
                                        id="uncontrolled-tab"
                                        className="mb-3"
                                    >
                                        <Tab eventKey="equipes" title="EQUIPES">
                                            <Row>
                                                <Col lg={12}>
                                                    <h3 className="title typeTwo blue">
                                                        Conheça nossas equipes
                                                    </h3>
                                                </Col>
                                            </Row>

                                            <Row className="mb-30">
                                                <Col lg={4}>
                                                    <div className="boxBordered">
                                                        <h4>RJ</h4>
                                                        <UsersRolesBox
                                                            name="Valéria Oliveira"
                                                            role="Gerente Adm-Fin"
                                                        />
                                                        <UsersRolesBox
                                                            name="Karinna Pires"
                                                            role="RH/DP"
                                                        />
                                                        <UsersRolesBox
                                                            name="Aline e Helaine"
                                                            role="Administrativos"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="boxBordered">
                                                        <h4>SP</h4>
                                                        <UsersRolesBox
                                                            name="Alexandre"
                                                            role="Financeiro"
                                                        />
                                                        <UsersRolesBox
                                                            name="Mitali"
                                                            role="Secretaria"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="boxBordered">
                                                        <h4>BSB</h4>
                                                        <UsersRolesBox
                                                            name="Thalita"
                                                            role="Financeiro"
                                                        />
                                                        <UsersRolesBox
                                                            name="Dimitra"
                                                            role="Secretaria"
                                                        />
                                                        <UsersRolesBox
                                                            name="Elaine Carmo"
                                                            role="Admnistrativo"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="mb-30">
                                                <Col lg={6}>
                                                    <div className="boxBordered">
                                                        <h4 className="title">
                                                            Direito Bancário
                                                        </h4>
                                                        <p className="mb-0">
                                                            Principais atuantes
                                                        </p>
                                                        <ul>
                                                            <li className="name">
                                                                Rafael Barroso
                                                                Fontelles
                                                            </li>
                                                            <li className="name">
                                                                Konstantinos
                                                                Andreopoulos
                                                            </li>
                                                            <li className="name">
                                                                Renato Rocha
                                                            </li>
                                                            <li className="name">
                                                                Felipe Barcellos
                                                            </li>
                                                            <li className="name">
                                                                Deborah Daher
                                                            </li>
                                                            <li className="name">
                                                                Camila Medim
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="boxBordered">
                                                        <h4>
                                                            Direito Digital e
                                                            Tecnologia
                                                        </h4>
                                                        <p className="mb-0">
                                                            Principais atuantes
                                                        </p>
                                                        <ul>
                                                            <li className="name">
                                                                Eduardo Mendonça
                                                            </li>
                                                            <li className="name">
                                                                Felipe Fonte
                                                            </li>
                                                            <li className="name">
                                                                Thiago Magalhães
                                                            </li>
                                                            <li className="name">
                                                                Roberta Mundim
                                                            </li>
                                                            <li className="name">
                                                                Felipe Terra
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-30">
                                                <Col lg={6}>
                                                    <div className="boxBordered">
                                                        <h4>
                                                            Direito em
                                                            Telecomunicações e
                                                            Outros Serviços
                                                        </h4>
                                                        <p className="mb-0">
                                                            Principais atuantes
                                                        </p>
                                                        <ul>
                                                            <li className="name">
                                                                Felipe Monnerat
                                                            </li>
                                                            <li className="name">
                                                                Paulo Henrique
                                                                Araújo
                                                            </li>
                                                            <li className="name">
                                                                Fábio Juliano
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="boxBordered">
                                                        <h4>Data Privacy</h4>
                                                        <p className="mb-0">
                                                            Principais atuantes
                                                        </p>
                                                        <ul>
                                                            <li className="name">
                                                                Eduardo Mendonça
                                                            </li>
                                                            <li className="name">
                                                                Felipe Monnerat
                                                            </li>
                                                            <li className="name">
                                                                André Zanatta
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-30">
                                                <Col lg={6}>
                                                    <div className="boxBordered">
                                                        <h4>
                                                            Direito
                                                            Administrativo –
                                                            Regulatório
                                                        </h4>
                                                        <p className="mb-0">
                                                            Principal atuante
                                                        </p>
                                                        <ul>
                                                            <li className="name">
                                                                Márcio Reis
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="boxBordered">
                                                        <h4>
                                                            Direito Tributário e
                                                            Energia
                                                        </h4>
                                                        <p className="mb-0">
                                                            Principal atuante
                                                        </p>
                                                        <ul>
                                                            <li className="name">
                                                                Felipe Fonte
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-30">
                                                <Col lg={6}>
                                                    <div className="boxBordered">
                                                        <h4>Arbitragem</h4>
                                                        <p className="mb-0">
                                                            Principal atuante
                                                        </p>
                                                        <ul>
                                                            <li className="name">
                                                                Carmen Tiburcio
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="boxBordered">
                                                        <h4>
                                                            Contencioso
                                                            Estratégico e
                                                            Agronegócio
                                                        </h4>
                                                        <p className="mb-0">
                                                            Principal atuante
                                                        </p>
                                                        <ul>
                                                            <li className="name">
                                                                Eduardo Nunez
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-30">
                                                <Col lg={6}>
                                                    <div className="boxBordered">
                                                        <h4>
                                                            Direito Público e
                                                            Constitucional
                                                        </h4>
                                                        <p className="mb-0">
                                                            Principais atuantes
                                                        </p>
                                                        <ul>
                                                            <li className="name">
                                                                Rafael Fontelles
                                                                Barroso
                                                            </li>
                                                            <li className="name">
                                                                Eduardo Mendonça
                                                            </li>
                                                            <li className="name">
                                                                Felipe Fonte
                                                            </li>
                                                            <li className="name">
                                                                Thiago Magalhães
                                                            </li>
                                                            <li className="name">
                                                                Ana Paula de
                                                                Barcellos
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="boxBordered">
                                                        <h4>Trabalhista</h4>
                                                        <p className="mb-0">
                                                            Principais atuantes
                                                        </p>
                                                        <ul>
                                                            <li className="name">
                                                                Felipe Monnerat
                                                            </li>
                                                            <li className="name">
                                                                Paulo Araújo
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-30">
                                                <Col>
                                                    <div className="boxBordered">
                                                        <h4>Clientes</h4>
                                                        <div className="imageGrid">
                                                            <img
                                                                src={require('../assets/images/logo-itau.png')}
                                                                alt="Banco Itaú"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-bradesco.png')}
                                                                alt="Banco Bradesco"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-banco-bv.png')}
                                                                alt="Banco BV"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-nubank.png')}
                                                                alt="Banco Nubank"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-vw.png')}
                                                                alt="Volkswagen"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-c6.png')}
                                                                alt="Banco C6"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-google.png')}
                                                                alt="Google"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-abraplex.png')}
                                                                alt="AbraPLex"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-uber.png')}
                                                                alt="Uber"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-telefonica.png')}
                                                                alt="Telefonica"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-sindi-telebras.png')}
                                                                alt="SindTelebras"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-verisure.png')}
                                                                alt="Verisure"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-algar.png')}
                                                                alt="Algar"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-serasa.png')}
                                                                alt="Serasa"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-claro.png')}
                                                                alt="Claro"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-facebook.png')}
                                                                alt="Facebook"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-quanto.png')}
                                                                alt="Quanto"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-abact.png')}
                                                                alt="ABACT"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-transpetro.png')}
                                                                alt="Transpetro"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-petrobras.png')}
                                                                alt="Petrobras"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-bnds.png')}
                                                                alt="BNDES"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-sicpa.png')}
                                                                alt="Sicpa"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-ceptis.png')}
                                                                alt="Ceptis"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-passarelli.png')}
                                                                alt="Passarelli"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-energisa.png')}
                                                                alt="Energisa"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-eletrobras.png')}
                                                                alt="Eletrobras"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-ldc.png')}
                                                                alt="LDC"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-biosev.png')}
                                                                alt="Biosev"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-gearbulk.png')}
                                                                alt="GearBulk"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-spot.png')}
                                                                alt="Spot Educação"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-hstern.png')}
                                                                alt="HStern"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-rededor.png')}
                                                                alt="Rede D'or"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-serpro.png')}
                                                                alt="Serpro"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-vibra.png')}
                                                                alt="Vibra"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-cnseg.png')}
                                                                alt="CNSeg"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-atento.png')}
                                                                alt="Atento"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-tribanco.png')}
                                                                alt="Tribanco"
                                                            />
                                                            <img
                                                                src={require('../assets/images/logo-sodexho.png')}
                                                                alt="Sodexho"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab
                                            eventKey="avaliacoes"
                                            title="AVALIAÇÕES"
                                        >
                                            <Row>
                                                <Col lg={12}>
                                                    <h3 className="title typeTwo blue">
                                                        Avaliações
                                                    </h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6}>
                                                    <Button
                                                        url="https://www.efix.net/bfbm/  "
                                                        target="_blank"
                                                        text="Inicie suas avaliações"
                                                    />
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab
                                            eventKey="movimentacoes"
                                            title="MOVIMENTAÇÕES / CONTRATAÇÕES E DESLIGAMENTOS"
                                        >
                                            <Row>
                                                <Col lg={12}>
                                                    <h3 className="title typeTwo blue">
                                                        Movimentações/Contratações
                                                        e Desligamentos
                                                    </h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6}>
                                                    <Button
                                                        url="https://app.pipefy.com/public/form/ESBOOGKy"
                                                        target="_blank"
                                                        text="Mudança de Área"
                                                        typeTwo
                                                        noIcon
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <Button
                                                        url="https://bfbm.gupy.io/"
                                                        target="_blank"
                                                        text="Vagas em Aberto"
                                                        typeTwo
                                                        noIcon
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <Button
                                                        url="https://app.pipefy.com/public/form/0nzPShHO"
                                                        target="_blank"
                                                        text="Ingresso de Colaborador (RJ)"
                                                        typeTwo
                                                        noIcon
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <Button
                                                        url="https://app.pipefy.com/public/form/WVnc0DdP"
                                                        target="_blank"
                                                        text="Desligamento de Colaborador (RJ)"
                                                        typeTwo
                                                        noIcon
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <Button
                                                        url="https://app.pipefy.com/public/form/Ojb7NCop"
                                                        target="_blank"
                                                        text="Ingresso de Colaborador (BSB)"
                                                        typeTwo
                                                        noIcon
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <Button
                                                        url="https://app.pipefy.com/public/form/sp0NiQeJ"
                                                        target="_blank"
                                                        text="Desligamento de Colaborador (BSB)"
                                                        typeTwo
                                                        noIcon
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <Button
                                                        url="https://app.pipefy.com/public/form/tVTy-03H"
                                                        target="_blank"
                                                        text="Ingresso de Colaborador (SP)"
                                                        typeTwo
                                                        noIcon
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <Button
                                                        url="https://app.pipefy.com/public/form/2UZGcBMc"
                                                        target="_blank"
                                                        text="Desligamento de Colaborador (SP)"
                                                        typeTwo
                                                        noIcon
                                                    />
                                                </Col>
                                            </Row>
                                        </Tab>
                                    </Tabs> */}
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Footer colorBlue={false} />
        </section>
    )
}

export default People
