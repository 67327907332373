import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Form, Navbar, Row } from 'react-bootstrap'

import VerticalNavigation from '../../../components/VerticalNavigation'
import Profile from './Profile'
import ButtonLogout from './ButtonLogout'

import style from '../../../components/TopBar/style.module.scss'

import logoBFBM from '../../../assets/images/logo-bfbm.svg'

function Header() {
    return (
        <section className={style.topbar}>
            <div className={style.desk}>
                <Container>
                    <Row className="align-items-center">
                        <Col lg={4}>
                            <div className={style.logoProfile}>
                                <Link to="/app/dashboard">
                                    <img
                                        src={logoBFBM}
                                        alt="Logotipo BFBM"
                                        className={style.logo}
                                    />
                                </Link>

                                <Profile />
                            </div>
                        </Col>

                        <Col lg={4}>
                            {/* <div className={style.searchBox}>
                                <Form>
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            placeholder="Pesquisar"
                                            className={style.inputSearch}
                                        />
                                    </Form.Group>
                                </Form>
                            </div> */}
                        </Col>

                        <Col
                            lg={4}
                            className={`${style.buttons} d-lg-flex justify-content-lg-end`}
                        >
                            <ButtonLogout />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Navbar expand="lg" className={style.mobile}>
                <Container>
                    <Navbar.Brand>
                        <Link to="/app/dashboard">
                            <img
                                src={logoBFBM}
                                alt="Logotipo BFBM"
                                className={style.logo}
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Row className="mt-3">
                            <Col className="d-flex justify-conten-center flex-column align-items-center">
                                <Profile />

                                <ButtonLogout />
                            </Col>
                        </Row>
                        <hr className="text-light my-3" />
                        <VerticalNavigation isTopBar />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </section>
    )
}

export default Header
