import React, { useCallback, useState } from 'react'
import { Button, Col, Row, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Calendar from 'react-calendar'
import moment from 'moment'
import 'moment/locale/pt-br'

import Spacer from '../Spacer'

import api from '../../api'
import { authHeader } from '../../_helpers/auth-header'

import style from './style.module.scss'

const Rooms = ({ location, types, setLocation, setTypes, event }) => {
    const navigate = useNavigate()
    const initalDefault = {
        event_id: event ? event.id : '',
        start_date: '',
        end_date: '',
        vacancies: 1,
        booking_by: '',
    }

    const [unAvailableHours, setUnAvailableHours] = useState([])

    const [room, setRoom] = useState([])

    const [dateState, setDateState] = useState(new Date())
    const [hourActive, setHourActive] = useState('')
    const [newEvent, setNewEvent] = useState({ ...initalDefault })

    const availableHours = [
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
    ]

    function changeDate(e) {
        if (hourActive) {
            setHourActive('')
        }
        setDateState(e)
        setNewEvent({
            ...newEvent,
            start_date: moment(e).locale('pt-br').format('YYYY-M-DD'),
            end_date: moment(e).endOf('month').format('YYYY-M-DD'),
        })
    }

    function getAllEvents(type) {
        api.get(`/events?type=${type}`)
            .then((response) => {
                if (response.data) {
                    setRoom(response.data)
                }
            })
            .catch((error) => {
                console.log('ERROR: ' + error)
            })
    }

    function getAllBookings(date) {
        const startDateInitFormat = moment(date || new Date())
            .locale('pt-br')
            .format('YYYY-MM-DD')

        api.get(
            `/events/bookings?start_date:init=${startDateInitFormat} 08:00&start_date:final=${startDateInitFormat} 20:00&event_type=${types}&event=${newEvent.event_id}`
        )
            .then((response) => {
                if (response.data && response.status === 200) {
                    const { data: bookings } = response

                    let unAvailableHours = bookings.map((booking) =>
                        moment(booking.start_at).locale('pt-br').format('HH:mm')
                    )

                    let resultAvailableHours = availableHours.filter(
                        (element) => {
                            if (unAvailableHours.indexOf(element) === -1) {
                                return element
                            }
                        }
                    )

                    setUnAvailableHours(resultAvailableHours)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    React.useEffect(() => {
        getAllEvents(types)
        setActive(event ? event.title : null)
        getAllBookings(new Date())
    }, [location, types])

    /* Component Nav Link 
    ----------------------------------- */
    const NavLink = ({ id, title, description, isActive, onClick }) => (
        <div
            className={`${style.wrapper} ${isActive ? style.active : ''}`}
            onClick={useCallback(() => {
                setNewEvent({
                    ...initalDefault,
                    event_id: id,
                })
                setDateState(null)
                setHourActive(null)
                onClick(id)
                getAllBookings(new Date())
            }, [id, onClick])}
        >
            <span className={style.name}>{title}</span>
            <span className={style.qty}>{description}</span>
        </div>
    )

    /* Component Parent 
    ----------------------------------- */
    const [active, setActive] = useState(null)

    const Parent = ({ menuItems }) => {
        return (
            <div>
                <ul className={style.list}>
                    {menuItems.map((item) => {
                        return (
                            <li key={item.id} className={style.item}>
                                <NavLink
                                    {...item}
                                    onClick={() => {
                                        setActive(item.title)
                                    }}
                                    isActive={active === item.title}
                                />
                            </li>
                        )
                    })}
                </ul>
                <ShowCalendar />
            </div>
        )
    }

    /* Component ShowCalendar 
    ----------------------------------- */
    const ShowCalendar = () => {
        const [isSuccess, setIsSuccess] = useState({})
        const [isError, setIsError] = useState(null)
        const [show, setShow] = useState(false)

        const dateFormat = moment(dateState).locale('pt-br').format('YYYY-M-DD')

        function handleClose() {
            setShow(false)
        }

        function handleShow() {
            setShow(true)
        }

        function toggleClass(hour) {
            setHourActive(hour)
        }

        function postBooking(event) {
            const hourFinal = !hourActive ? '08:00' : hourActive.toString()
            const dateFinal = newEvent.start_date
                .concat(' ', hourFinal)
                .toString()

            api.post(
                '/events/bookings',
                {
                    event_id: newEvent.event_id,
                    start_date: moment(dateFinal)
                        .locale('pt-br')
                        .format('YYYY-MM-DD HH:mm:ss'),
                    end_date: moment(dateFinal)
                        .locale('pt-br')
                        .endOf('month')
                        .format('YYYY-MM-DD HH:mm:ss'),
                    vacancies: 1,
                    booking_by: JSON.parse(localStorage.getItem('me')).id,
                },
                { authHeader }
            )
                .then((response) => {
                    if (response.data && response.status === 201) {
                        handleShow()
                        setIsSuccess(response.data)
                    }
                })
                .catch((err) => {
                    let message =
                        typeof err.response !== 'undefined'
                            ? err.response.data.message
                            : err.message
                    setIsError(message)
                })
        }

        function disabledWeekFinal(date, view) {
            if (view === 'month') {
                return (
                    new Date(date).getDay() === 0 ||
                    new Date(date).getDay() === 6
                )
            }
        }

        if (active != null) {
            return (
                <div>
                    <Calendar
                        value={dateState}
                        onChange={changeDate}
                        minDate={moment().toDate()}
                        prev2Label={null}
                        next2Label={null}
                        onClickDay={(value) => getAllBookings(value)}
                        calendarType="US"
                        defaultView="month"
                        tileDisabled={({ date, view }) => {
                            return disabledWeekFinal(date, view)
                        }}
                        onViewChange={(action, date, view) => {
                            console.log('*** Action', action)
                        }}
                    />
                    <Spacer size={30} />
                    <hr />
                    <Spacer size={30} />

                    {types !== 'workspace' && (
                        <>
                            {unAvailableHours.length === 0 && (
                                <p>Nenhum horário disponível para essa data</p>
                            )}

                            <ul className={style.hoursList}>
                                {unAvailableHours.map((hour, index) => (
                                    <li key={index}>
                                        <span
                                            onClick={() => toggleClass(hour)}
                                            className={
                                                hourActive === hour
                                                    ? style.active
                                                    : null
                                            }
                                        >
                                            {hour}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}

                    <Spacer size={30} />

                    <Row>
                        <Col lg={4}>
                            <button
                                className={`btn btn-primary button ${style.button}`}
                                onClick={() => {
                                    postBooking(newEvent)
                                }}
                                disabled={
                                    types !== 'workspace'
                                        ? !dateState || !hourActive
                                        : !dateState
                                }
                            >
                                Confirmar
                            </button>
                        </Col>
                    </Row>

                    {isError && (
                        <Row>
                            <Col>
                                <p className="mt-2 bg-danger text-white rounded p-3">
                                    {isError[Object.keys(isError)]}
                                </p>
                            </Col>
                        </Row>
                    )}

                    {/* Modal ---------------------------- */}
                    <Modal show={show} onHide={handleClose} centered>
                        <Modal.Header className="border-0" />
                        <Modal.Body>
                            <div className="p-5 text-center">
                                <h5 className="mb-0">
                                    Reserva concluída com sucesso
                                </h5>
                                <strong
                                    className="text-uppercase"
                                    style={{ color: '#15599d' }}
                                >
                                    {location}
                                    {' | '}
                                    {moment(new Date(isSuccess.start_at))
                                        .locale('pt-br')
                                        .format('DD MMM')}
                                    {types !== 'workspace' ? (
                                        <>
                                            {' | '}
                                            {moment(
                                                new Date(isSuccess.start_at)
                                            )
                                                .locale('pt-br')
                                                .utc()
                                                .format('HH:mm')}
                                        </>
                                    ) : null}
                                </strong>
                                <Button
                                    variant="primary"
                                    className="button mt-3"
                                    onClick={() => {
                                        setNewEvent({ ...initalDefault })
                                        setActive(null)
                                        setRoom([])
                                        handleClose()
                                        setUnAvailableHours([])
                                        setHourActive('')
                                        setLocation('')
                                        setTypes(null)
                                        navigate('/app/agenda')
                                    }}
                                >
                                    Fechar
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )
        } else {
            return ''
        }
    }

    return (
        <section className={style.roomContainer}>
            <Row>
                <Col>
                    <Parent
                        menuItems={room.filter(
                            (item) => item.state_uf === location && item
                        )}
                    />
                </Col>
            </Row>
        </section>
    )
}

export default Rooms
