import React from 'react'
import { Button } from 'rsuite'

import useFetch from '../../../hook/useFetch'
import api from '../../../../api'
import { authHeader } from '../../../../_helpers/auth-header'

function DrawerForm({ action, setAction }) {
    const {
        data: { id, name, email, birthday, role, occupation, avatar, state_id },
        behavior,
    } = action

    const initialState = {
        name: behavior === 'update' ? name : '',
        email: behavior === 'update' ? email : '',
        birthday: behavior === 'update' ? birthday : '',
        role: behavior === 'update' ? role : 0,
        occupation: behavior === 'update' ? occupation : '',
        avatar: behavior === 'update' ? avatar : '',
        state_id: behavior === 'update' ? state_id : 0,
    }

    const initialUpload = {
        preview: null,
        origin: null,
        base_64: null,
    }

    const { data: states } = useFetch('/locations/states')

    const [form, setForm] = React.useState({ ...initialState })
    const [changePassword, setChangePassword] = React.useState({
        active: false,
    })
    const [upload, setUpload] = React.useState(initialUpload)
    const [isError, setIsError] = React.useState(null)

    function handleChange({ target }) {
        const { id, value } = target
        setForm({ ...form, [id]: value })
    }

    async function handleUpload({ target }) {
        const reader = new FileReader()

        reader.readAsDataURL(target.files[0])
        reader.onloadend = () => {
            setUpload({
                preview: URL.createObjectURL(target.files[0]),
                origin: target.files[0],
                base_64: reader.result,
            })
            setForm({ ...form, avatar: reader.result })
        }
    }

    function update(id) {
        api.put(
            `/users/${id}`,
            {
                name: form.name,
                email: form.email,
                password: form.password,
                birthday: form.birthday,
                role: form.role,
                occupation: form.occupation,
                avatar: upload.base_64,
                state_id: form.state_id,
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 200) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function create() {
        api.post(
            '/users',
            {
                name: form.name,
                email: form.email,
                password: form.password,
                birthday: form.birthday,
                role: form.role,
                occupation: form.occupation,
                avatar: upload.base_64,
                state_id: form.state_id,
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 200) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let messages =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(messages)

                Object.keys(messages).map((item) => {
                    console.log('*** Error ', messages[item])
                })
            })
    }

    function handleSubmit(e) {
        e.preventDefault()

        if (behavior === 'create') {
            create()
            return
        }

        if (behavior === 'update') {
            update(id)
            return
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <span className="form-group mb-4 d-block">
                {upload.preview || form.avatar ? (
                    <div className="preview">
                        <img
                            style={{ width: '90px', height: '90px' }}
                            className="rounded mx-auto d-block mb-3 img-fluid"
                            src={upload.preview || form.avatar}
                        />
                        <Button
                            appearance="primary"
                            size="xs"
                            color="red"
                            onClick={() => {
                                setUpload({ ...upload, preview: null })
                                setForm({ ...form, avatar: '' })
                            }}
                        >
                            Excluir
                        </Button>
                    </div>
                ) : (
                    <>
                        <label>Imagem</label>
                        <input
                            className="form-control"
                            id="avatar"
                            type="file"
                            onChange={handleUpload}
                            required
                        />
                    </>
                )}
            </span>

            <span className="form-group mb-4 d-block">
                <label>Nome</label>
                <input
                    className="form-control"
                    id="name"
                    type="text"
                    onChange={handleChange}
                    value={form.name}
                    required
                />
            </span>

            <span className="form-group mb-4 d-block">
                <label>E-mail</label>
                <input
                    className="form-control"
                    id="email"
                    type="email"
                    onChange={handleChange}
                    value={form.email}
                    required
                />
            </span>

            <span className="form-group mb-4 d-block">
                <label>Senha</label>
                <br />
                {behavior === 'create' && (
                    <input
                        className="form-control"
                        id="password"
                        type="password"
                        onChange={handleChange}
                        value={form.password}
                        required
                    />
                )}

                {behavior === 'update' && (
                    <>
                        {!changePassword && (
                            <input
                                className="form-control"
                                id="password"
                                type="password"
                                onChange={handleChange}
                                value={form.password}
                                onBlur={(e) =>
                                    setForm({
                                        ...form,
                                        password: e.target.value,
                                    })
                                }
                                required
                            />
                        )}
                        <Button
                            appearance="link"
                            size="xs"
                            color="blue"
                            className="px-0"
                            onClick={() => {
                                setChangePassword(!changePassword)
                            }}
                        >
                            {changePassword ? 'Editar senha' : 'Ocultar senha'}
                        </Button>
                    </>
                )}
            </span>

            <span className="form-group mb-4 d-block">
                <label>Ocupação</label>
                <input
                    className="form-control"
                    id="occupation"
                    type="text"
                    onChange={handleChange}
                    value={form.occupation}
                    required
                />
            </span>

            <span className="form-group mb-4 d-block">
                <label>Data de Nascimento</label>
                <input
                    className="form-control"
                    id="birthday"
                    type="date"
                    onChange={handleChange}
                    value={form.birthday}
                    required
                />
            </span>

            <span className="form-group mb-4 d-block">
                <label>Tipo de permissão</label>
                <select
                    id="role"
                    className="form-select"
                    onChange={handleChange}
                    value={form.role}
                >
                    <option value="0" disabled>
                        Selecione a permissão
                    </option>
                    <option value="customer">Customer</option>
                    <option value="admin">Admin</option>
                </select>
            </span>

            <span className="form-group mb-4 d-block">
                <label>Estado</label>
                <select
                    id="state_id"
                    className="form-select"
                    onChange={handleChange}
                    value={form.state_id}
                >
                    <option value="0" disabled>
                        Selecione o estado
                    </option>
                    {states.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.title}
                        </option>
                    ))}
                </select>
            </span>

            <span className="form-group">
                <button className="btn btn-primary">
                    {behavior === 'create' ? 'Salvar' : 'Atualizar'}
                </button>
            </span>

            {isError && (
                <div className="row">
                    <div className="col">
                        {Object.keys(isError).map((item) => (
                            <p
                                key={item}
                                className="mt-2 bg-danger text-white rounded p-3"
                            >
                                {isError[item]}
                            </p>
                        ))}
                    </div>
                </div>
            )}
        </form>
    )
}

export default DrawerForm
