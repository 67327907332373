import React from 'react'
import { Navigate } from 'react-router-dom'
import { Loader } from 'rsuite'

import Dashboard from '../admin/pages/Dashboard'

import { authHeader } from '../_helpers/auth-header'
import api from '../api'

function AdminPanel() {
    const [isLoading, setIsLoading] = React.useState(true)
    const [data, setData] = React.useState({})

    function getAuthMe() {
        api.get('/auth/me', { authHeader })
            .then((response) => {
                if (response.data && response.status === 200) {
                    const { data } = response
                    setData(data)
                }
            })
            .catch((error) => console.log('Error:' + error))
            .finally(() => {
                setIsLoading(false)
            })
    }

    React.useEffect(() => {
        getAuthMe()
    }, [])

    return (
        <div>
            {isLoading && (
                <div
                    className="w-100 d-flex align-items-center flex-column justify-content-center text-muted"
                    style={{ height: '100vh' }}
                >
                    <Loader
                        size="lg"
                        content="Carregando área administrativa..."
                        vertical
                    />
                </div>
            )}
            {!isLoading && data.role !== 'admin' ? (
                <Navigate to="/app/dashboard" />
            ) : (
                <Dashboard />
            )}
        </div>
    )
}

export default AdminPanel
