import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Button, FlexboxGrid, IconButton } from 'rsuite'
import TopBar from '../components/TopBar'
import VerticalNavigation from '../components/VerticalNavigation'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'
import Card from '../components/Card'

import VisibleIcon from '@rsuite/icons/Visible'
import UnvisibleIcon from '@rsuite/icons/Unvisible'

import useFetch from '../admin/hook/useFetch'
import api from '../api'
import { authHeader } from '../_helpers/auth-header'

import style from '../scss/pages/dashboard.module.scss'
import { useNavigate } from 'react-router-dom'
import Loader from '../components/Loader'

const Profile = () => {
    const navigate = useNavigate()
    const { data: states } = useFetch('/locations/states')

    const initialUpload = {
        preview: null,
        origin: null,
        base_64: null,
    }

    const [form, setForm] = React.useState({})
    const [changePassword, setChangePassword] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false)
    const [upload, setUpload] = React.useState(initialUpload)
    const [isError, setIsError] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(true)

    function handleChange({ target }) {
        const { id, value } = target
        setForm({ ...form, [id]: value })
    }

    async function handleUpload({ target }) {
        const reader = new FileReader()

        reader.readAsDataURL(target.files[0])
        reader.onloadend = () => {
            setUpload({
                preview: URL.createObjectURL(target.files[0]),
                origin: target.files[0],
                base_64: reader.result,
            })
            setForm({ ...form, avatar: reader.result })
        }
    }

    function getUserByOne() {
        api.get(`/auth/me`)
            .then((response) => {
                if (response.status === 200) {
                    setForm(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    function update(id) {
        api.put(
            `/users/${id}`,
            {
                name: form.name,
                email: form.email,
                password: form.password,
                birthday: form.birthday,
                occupation: form.occupation,
                avatar: upload.base_64,
                state_id: form.state_id,
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 200) {
                    navigate('/app/dashboard')
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function handleSubmit(e) {
        e.preventDefault()
        update(form.id)
    }

    function validatePass(password) {
        if (password) {
            const validPassword =
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
                    password
                )
            return validPassword
        }
    }

    React.useEffect(() => {
        getUserByOne()
    }, [])

    return (
        <section className={`content ${style.dashboard}`}>
            <TopBar />
            <Spacer size={90} />
            <Container>
                <Row>
                    <Col lg={3}>
                        <VerticalNavigation />
                    </Col>
                    <Col lg={9}>
                        <Row>
                            <Col>
                                <h2 className="title blue">Editar Perfil</h2>
                            </Col>
                        </Row>

                        <Row>
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <Col>
                                    <Card>
                                        <form onSubmit={handleSubmit}>
                                            <span className="form-group mb-4 d-block">
                                                {upload.preview ||
                                                form.avatar ? (
                                                    <div className="preview">
                                                        <img
                                                            style={{
                                                                width: '90px',
                                                                height: '90px',
                                                            }}
                                                            className="rounded mx-auto d-block mb-3 img-fluid"
                                                            src={
                                                                upload.preview ||
                                                                form.avatar
                                                            }
                                                        />
                                                        <Button
                                                            appearance="primary"
                                                            size="xs"
                                                            color="red"
                                                            onClick={() => {
                                                                setUpload({
                                                                    ...upload,
                                                                    preview:
                                                                        null,
                                                                })
                                                                setForm({
                                                                    ...form,
                                                                    avatar: '',
                                                                })
                                                            }}
                                                        >
                                                            Excluir
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <label>Imagem</label>
                                                        <input
                                                            className="form-control"
                                                            id="avatar"
                                                            type="file"
                                                            onChange={
                                                                handleUpload
                                                            }
                                                            required
                                                        />
                                                    </>
                                                )}
                                            </span>

                                            <span className="form-group mb-4 d-block">
                                                <label>Nome</label>
                                                <input
                                                    className="form-control"
                                                    id="name"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={form.name}
                                                    required
                                                />
                                            </span>

                                            <span className="form-group mb-4 d-block">
                                                <label>E-mail</label>
                                                <input
                                                    className="form-control"
                                                    id="email"
                                                    type="email"
                                                    onChange={handleChange}
                                                    value={form.email}
                                                    required
                                                />
                                            </span>

                                            <span className="form-group mb-4 d-block">
                                                <label>Senha</label>
                                                <br />
                                                <>
                                                    {changePassword && (
                                                        <div
                                                            className="d-flex"
                                                            style={{
                                                                gap: '12px',
                                                            }}
                                                        >
                                                            <input
                                                                className="col form-control"
                                                                id="password"
                                                                type={
                                                                    showPassword
                                                                        ? 'password'
                                                                        : 'text'
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    form.password
                                                                }
                                                                onBlur={(e) =>
                                                                    setForm({
                                                                        ...form,
                                                                        password:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                                required
                                                            />
                                                            <IconButton
                                                                appearance="primary"
                                                                icon={
                                                                    showPassword ? (
                                                                        <UnvisibleIcon />
                                                                    ) : (
                                                                        <VisibleIcon />
                                                                    )
                                                                }
                                                                onClick={() => {
                                                                    setShowPassword(
                                                                        !showPassword
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    )}

                                                    {changePassword &&
                                                        !validatePass(
                                                            form?.password
                                                        ) && (
                                                            <p className="text-danger mb-1">
                                                                Deve conter
                                                                mínimo de 8
                                                                caracteres,
                                                                letras
                                                                maiusculas,
                                                                letras
                                                                minusculas,
                                                                números e
                                                                caracteres
                                                                especiais.
                                                            </p>
                                                        )}

                                                    <Button
                                                        appearance="link"
                                                        size="xs"
                                                        color="blue"
                                                        className="px-0"
                                                        onClick={() => {
                                                            setChangePassword(
                                                                !changePassword
                                                            )
                                                        }}
                                                    >
                                                        {changePassword
                                                            ? 'Ocultar senha'
                                                            : 'Editar senha'}
                                                    </Button>
                                                </>
                                            </span>

                                            <span className="form-group mb-4 d-block">
                                                <label>Ocupação</label>
                                                <input
                                                    className="form-control"
                                                    id="occupation"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={form.occupation}
                                                    required
                                                />
                                            </span>

                                            <span className="form-group mb-4 d-block">
                                                <label>
                                                    Data de Nascimento
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="birthday"
                                                    type="date"
                                                    onChange={handleChange}
                                                    value={form.birthday}
                                                    required
                                                />
                                            </span>

                                            <span className="form-group mb-4 d-block">
                                                <label>Estado</label>
                                                <select
                                                    id="state_id"
                                                    className="form-select"
                                                    onChange={handleChange}
                                                    value={form.state_id}
                                                >
                                                    <option value="0" disabled>
                                                        Selecione o estado
                                                    </option>
                                                    {states.map((item) => (
                                                        <option
                                                            key={item.id}
                                                            value={item.id}
                                                        >
                                                            {item.title}
                                                        </option>
                                                    ))}
                                                </select>
                                            </span>
                                            {/* @sdfgh2K */}
                                            <span className="form-group">
                                                <button className="btn btn-primary">
                                                    Atualizar
                                                </button>
                                            </span>

                                            {isError && (
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="mt-2 bg-danger text-white rounded p-3">
                                                            {isError}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </form>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Footer colorBlue={false} />
        </section>
    )
}

export default Profile
