import React, {useEffect, useState} from 'react'
import {Accordion, Col, Container, Row, Tabs, Tab} from 'react-bootstrap'
import TopBar from '../components/TopBar'
import VerticalNavigation from '../components/VerticalNavigation'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'
import Card from '../components/Card'
import axios from 'axios'
import api from '../api'
import style from '../scss/pages/dashboard.module.scss'
import Loader from "../components/Loader";

const Services = () => {
  const [services, setServices] = useState([])
  const [loading, setLoading] = useState(true)

  const [servicesStates, setServicesStates] = useState([])
  const [servicesCategories, setServicesCategories] = useState([])
 
    const fetchData = () => {
    setLoading(true)

    const servicesStatesURL = "/service-states"
    const servicesCategoriesURL = "/service-categories"
    const servicesURL = "/services"

    const servicesStatesData = api.get(servicesStatesURL)
    const servicesCategoriesData = api.get(servicesCategoriesURL)
    const servicesData = api.get(servicesURL)

    axios.all([servicesStatesData, servicesCategoriesData,servicesData]).then(
      axios.spread((...responses) => {
        setServicesStates(responses[0].data)
        setServicesCategories(responses[1].data)
        setServices(responses[2].data)
        setLoading(false)
      })
    )
    .catch((err) => {
      console.error("Ops! Houve um erro ao realizar as requisições: " + err);
      setLoading(false)
    });
  }
  
  useEffect(() => {
    fetchData()
  }, [])

  const getServicesCategories = (serviceCategorie, serviceState) => {
    for (let i=0; i< services.length; i++){
      if (services[i].service_categories_id === serviceCategorie.id && services[i].service_states_id === serviceState.id){
        return true;
      }     
    }    
  }    

  return (
    <section className={`content ${style.dashboard}`}>
      <TopBar/>
      <Spacer size={90}/>
      <Container>
        <Row>
          <Col lg={3}>
            <VerticalNavigation/>
          </Col>
          <Col lg={9}>
            <Row>
              <Col>
                <h2 className='title blue'>Serviços</h2>
              </Col>
            </Row>
            <Row>
              {loading && <Loader/>}
              {(services.length === 0 && loading === false) && <Card title="Nenhum serviço encontrado!" titleGray/>}
              <Col>
                {!loading && (
                  <Card>
                    <Tabs defaultActiveKey="tab0" id="uncontrolled-tab-example" className="mb-3">
                      {servicesStates.map((serviceState, index) => {
                        return (
                          <Tab eventKey={`tab${index}`} title={serviceState.title}>
                            <Row>
                              <Accordion>
                               {servicesCategories.map((servicesCategorie, index) => (                                
                                    getServicesCategories(servicesCategorie, serviceState) ?
                                    (<Accordion.Item eventKey={`accor${index}`}>
                                      <Accordion.Header>{servicesCategorie.title}</Accordion.Header>
                                      <Accordion.Body>
                                        {services.map((service, index) => {     
                                          return(                                              
                                          service.service_categories_id === servicesCategorie.id && service.service_states_id === serviceState.id ? 
                                       (
                                            <div className='item'>                                           
                                              {service.title && <p><strong>{service.title}</strong></p>}
                                              {service.info && <p>{service.info}</p>}                                           
                                            </div>
                                          ): <div key={service.id}/>
                                          )})}
                                      </Accordion.Body>
                                    </Accordion.Item>) : <div key={servicesCategorie.id}/>
                                  ))} 
                              </Accordion>
                            </Row>
                          </Tab>
                        )
                      })}
                    </Tabs>
                  </Card>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer colorBlue={false}/>
    </section>
  );
}

export default Services