import React from 'react'

import useFetch from '../../../hook/useFetch'
import api from '../../../../api'
import { authHeader } from '../../../../_helpers/auth-header'

function DrawerForm({ action, setAction }) {
    const {
        data: { id, title, info, link, branch_line_category_id },
        behavior,
    } = action

    const initialState = {
        title: behavior === 'update' ? title : '',
        info: behavior === 'update' ? info : '',
        link: behavior === 'update' ? link : '',
        branch_line_category_id:
            behavior === 'update' ? branch_line_category_id : 0,
    }

    const { data } = useFetch('/branch-line-categories')

    const [form, setForm] = React.useState({ ...initialState })
    const [isError, setIsError] = React.useState(null)

    function handleChange({ target }) {
        const { id, value } = target
        setForm({ ...form, [id]: value })
    }

    function update(id) {
        api.put(
            `/branch-lines/${id}`,
            {
                title: form.title,
                info: form.info,
                link: form.link,
                branch_line_category_id: parseInt(form.branch_line_category_id),
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 200) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function create() {
        api.post(
            '/branch-lines',
            {
                title: form.title,
                info: form.info,
                link: form.link,
                branch_line_category_id: parseInt(form.branch_line_category_id),
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 200) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function handleSubmit(e) {
        e.preventDefault()

        if (behavior === 'create') {
            create()
            return
        }

        if (behavior === 'update') {
            update(id)
            return
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <span className="form-group mb-4 d-block">
                <label>Título</label>
                <input
                    className="form-control"
                    id="title"
                    type="text"
                    onChange={handleChange}
                    value={form.title}
                    required
                />
            </span>

            <span className="form-group mb-4 d-block">
                <label>Informação</label>
                <input
                    className="form-control"
                    id="info"
                    type="text"
                    onChange={handleChange}
                    value={form.info}
                    required
                />
            </span>

            <span className="form-group mb-4 d-block">
                <label>Link</label>
                <input
                    className="form-control"
                    id="link"
                    type="text"
                    onChange={handleChange}
                    value={form.link}
                    required
                />
            </span>

            <span className="form-group mb-4 d-block">
                <label>Categoria</label>
                <select
                    id="branch_line_category_id"
                    className="form-select"
                    onChange={handleChange}
                    value={form.branch_line_category_id}
                >
                    <option value="0" disabled>
                        Selecione a categoria
                    </option>
                    {data.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.title}
                        </option>
                    ))}
                </select>
            </span>

            <span className="form-group">
                <button className="btn btn-primary">
                    {behavior === 'create' ? 'Salvar' : 'Atualizar'}
                </button>
            </span>

            {isError && (
                <div className="row">
                    <div className="col">
                        <p className="mt-2 bg-danger text-white rounded p-3">
                            {isError[Object.keys(isError)]}
                        </p>
                    </div>
                </div>
            )}
        </form>
    )
}

export default DrawerForm
