import React from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'

import useFetch from '../../../hook/useFetch'
import { authHeader } from '../../../../_helpers/auth-header'
import api from '../../../../api'

function DrawerForm({ action, setAction }) {
    const {
        data: { id, event_id, start_date, end_date, vacancies },
        behavior,
    } = action

    const initialState = {
        event_id: behavior === 'update' ? event_id : '',
        start_date: behavior === 'update' ? start_date : '',
        end_date: behavior === 'update' ? end_date : '',
        vacancies: behavior === 'update' ? vacancies : 1,
    }

    const { data: getAllEventByLecture } = useFetch('/events?type=lecture')

    const [form, setForm] = React.useState({ ...initialState })
    const [time, setTime] = React.useState('')

    const [isError, setIsError] = React.useState(null)

    const currentDate = `${form.start_date} ${time}`

    function handleChange({ target }) {
        const { id, value } = target
        setForm({ ...form, [id]: value })
    }

    function update(id) {
        api.put(
            `events/schedules/${id}`,
            {
                event_id: form.event_id,
                start_date: form.start_date,
                end_date: form.start_date,
                vacancies: 1,
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 200) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function create() {
        api.post(
            'events/schedules',
            {
                event_id: form.event_id,
                start_date: moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
                vacancies: 1,
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 201) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function handleSubmit(e) {
        e.preventDefault()

        if (behavior === 'create') {
            create()
            return
        }

        if (behavior === 'update') {
            update(id)
            return
        }
    }

    return (
        <form onSubmit={handleSubmit}>

            <span className="form-group mb-4 d-block">
                <label>Palestras</label>
                <select
                    id="event_id"
                    className="form-select"
                    onChange={handleChange}
                    value={form.event_id}
                >
                    <option value="0">Selecione a Palestra</option>
                    {getAllEventByLecture &&
                        getAllEventByLecture.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.title}
                            </option>
                        ))}
                </select>
            </span>

            <span className="form-group mb-4 d-block">
                <label>Data</label>
                <input
                    type="date"
                    className="form-control"
                    id="start_date"
                    onChange={handleChange}
                    value={form.start_date}
                    required
                ></input>
            </span>

            {form.start_date ? (
                <span className="form-group mb-4 d-block">
                    <label>Horário</label>
                    <input
                        className="form-control"
                        type="time"
                        value={time}
                        onChange={({ target }) => setTime(target.value)}
                        required
                    ></input>
                </span>
            ) : null}

            <span className="form-group">
                <button className="btn btn-primary">
                    {behavior === 'create' ? 'Salvar' : 'Atualizar'}
                </button>
            </span>

            {isError && (
                <div className="row">
                    <div className="col">
                        <p className="mt-2 bg-danger text-white rounded p-3">
                            {isError[Object.keys(isError)]}
                        </p>
                    </div>
                </div>
            )}
        </form>
    )
}

export default DrawerForm
