import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import TopBar from '../components/TopBar'
import VerticalNavigation from '../components/VerticalNavigation'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'
import Card from '../components/Card'
import axios from 'axios'
import style from '../scss/pages/dashboard.module.scss'
import api from '../api'
import Button from '../components/Button'
import Loader from '../components/Loader'

const Litigation = () => {
    const [loading, setLoading] = useState(true)
    const [researches, setResearches] = useState([])
    const [researchCategories, setResearchCategories] = useState([])

    const fetchData = () => {
        setLoading(true)

        const researchCategoriesURL = '/research-categories'
        const reseacrhesURL = '/research-category-links'

        const researchCategoriesData = api.get(researchCategoriesURL)
        const researchesData = api.get(reseacrhesURL)

        axios
            .all([researchCategoriesData, researchesData])
            .then(
                axios.spread((...responses) => {
                    setResearchCategories(responses[0].data)
                    setResearches(responses[1].data)
                    setLoading(false)
                })
            )
            .catch((err) => {
                console.error(
                    'Ops! Houve um erro ao realizar as requisições: ' + err
                )
                setLoading(false)
            })
    }

    function validateURL(url) {
        const valid = /^(ftp|http|https):\/\/[^ "]+$/.test(url)
        return valid
    }

    const handleDownload = (id) => {
        api.get(`/attachments/download/${id}`, { responseType: 'blob' })
            .then((response) => {
                const blob = response.data
                const contentType = response.headers['content-type']
                const contentDisposition =
                    response.headers['content-disposition']

                let filename = ''

                if (
                    contentDisposition &&
                    contentDisposition.indexOf('attachment') !== -1
                ) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                    let matches = filenameRegex.exec(contentDisposition)
                    if (matches != null && matches[1])
                        filename = matches[1].replace(/['"]/g, '')
                }

                const file = new Blob([blob], { type: contentType })
                const fileURL = URL.createObjectURL(file)

                if (filename) {
                    let linkDownload = document.createElement('a')

                    linkDownload.setAttribute('href', fileURL)
                    linkDownload.setAttribute('download', filename)

                    linkDownload.click()
                }
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <section className={`content ${style.dashboard}`}>
            <TopBar />
            <Spacer size={90} />
            <Container>
                <Row>
                    <Col lg={3}>
                        <VerticalNavigation />
                    </Col>
                    <Col lg={9}>
                        <Row>
                            <Col>
                                <h2 className="title blue">
                                    Contencioso e Pesquisa
                                </h2>
                            </Col>
                        </Row>
                        {loading && <Loader />}
                        {researchCategories.length === 0 && !loading && (
                            <Card title="Nada foi encontrado!" titleGray />
                        )}

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <Row>
                                        {researches
                                            .filter(
                                                ({ research_category_id }) =>
                                                    research_category_id === 3
                                            )
                                            .map((research) => (
                                                <Col lg={6} key={research.id}>
                                                    {validateURL(
                                                        research.url
                                                    ) ? (
                                                        <Button
                                                            key={research.id}
                                                            url={research.url}
                                                            target="_blank"
                                                            text={
                                                                research.title
                                                            }
                                                            typeTwo
                                                            noIcon
                                                            alignCenter
                                                        />
                                                    ) : (
                                                        <Button
                                                            key={research.id}
                                                            typeTwo
                                                            noIcon
                                                            alignCenter
                                                            text={
                                                                research.title
                                                            }
                                                            onClick={() => {
                                                                handleDownload(
                                                                    research.attachment_id
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                </Col>
                                            ))}
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            {researchCategories.map((researchCategorie) =>
                                researchCategorie.title ===
                                'Contencioso e Pesquisa' ? (
                                    <div />
                                ) : (
                                    <Col lg={6} key={researchCategorie.id}>
                                        <Card title="Formulário de Pesquisa">
                                            <h3 className="title typeTwo">
                                                {researchCategorie.title}
                                            </h3>
                                            {researches.map((research) =>
                                                researchCategorie.id !==
                                                research.research_category_id ? (
                                                    <div key={research.id} />
                                                ) : validateURL(
                                                      research.url
                                                  ) ? (
                                                    <Button
                                                        key={research.id}
                                                        ar
                                                        url={research.url}
                                                        target="_blank"
                                                        text={research.title}
                                                        typeTwo
                                                        noIcon
                                                    />
                                                ) : (
                                                    <Button
                                                        key={research.id}
                                                        typeTwo
                                                        noIcon
                                                        text={research.title}
                                                        onClick={() => {
                                                            console.log(
                                                                'Testando...'
                                                            )
                                                        }}
                                                    />
                                                )
                                            )}
                                        </Card>
                                    </Col>
                                )
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Footer colorBlue={false} />
        </section>
    )
}

export default Litigation
