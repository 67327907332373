import React from 'react'

import useFetch from '../../../hook/useFetch'
import api from '../../../../api'
import { authHeader } from '../../../../_helpers/auth-header'

function DrawerForm({ action, setAction }) {
    const {
        data: { id, name, email, city_id, state_id },
        behavior,
    } = action

    const initialState = {
        name: behavior === 'update' ? name : '',
        email: behavior === 'update' ? email : '',
        city_id: behavior === 'update' ? city_id : 0,
    }

    const [states, setStates] = React.useState(
        behavior === 'update' ? state_id : 0
    )
    const [city, setCity] = React.useState(0)

    const [form, setForm] = React.useState({ ...initialState })
    const [isError, setIsError] = React.useState(null)

    const { data: statesAll } = useFetch('/locations/states')

    function handleChange({ target }) {
        const { id, value } = target
        setForm({ ...form, [id]: value })
    }

    function update(id) {
        api.put(
            `/contacts/${id}`,
            {
                name: form.name,
                email: form.email,
                city_id: form.city_id,
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 200) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function create() {
        api.post(
            '/contacts',
            {
                name: form.name,
                email: form.email,
                city_id: form.city_id,
            },
            { authHeader }
        )
            .then((response) => {
                if (response.status === 201) {
                    setAction({
                        ...action,
                        behavior: '',
                        drawer: false,
                    })
                }
            })
            .catch((error) => {
                let message =
                    typeof error.response !== 'undefined'
                        ? error.response.data.message
                        : error.message
                setIsError(message)
            })
    }

    function handleSubmit(e) {
        e.preventDefault()

        if (behavior === 'create') {
            create()
            return
        }

        if (behavior === 'update') {
            update(id)
            return
        }
    }

    React.useEffect(() => {
        if (states !== 0) {
            api.get(`/locations/states/${states}/cities`).then((response) => {
                if (response.status === 200) setCity(response.data)
            })
        }
    }, [states])

    return (
        <form onSubmit={handleSubmit}>
            <span className="form-group mb-4 d-block">
                <label>Título</label>
                <input
                    className="form-control"
                    id="name"
                    type="text"
                    onChange={handleChange}
                    value={form.name}
                    required
                />
            </span>

            <span className="form-group mb-4 d-block">
                <label>email</label>
                <input
                    className="form-control"
                    id="email"
                    type="email"
                    onChange={handleChange}
                    value={form.email}
                    required
                />
            </span>

            <span className="form-group mb-4 d-block">
                <label>Estado</label>
                <select
                    id="states"
                    className="form-select"
                    onChange={(e) => setStates(e.target.value)}
                    value={states}
                >
                    <option value="0" disabled>
                        Selecione o Estado
                    </option>
                    {statesAll &&
                        statesAll.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.title}
                            </option>
                        ))}
                </select>
            </span>

            {states !== 0 && (
                <span className="form-group mb-4 d-block">
                    <label>Cidade</label>
                    <select
                        id="city_id"
                        className="form-select"
                        onChange={handleChange}
                        value={form.city_id}
                    >
                        <option value="0" disabled>
                            Selecione a cidade
                        </option>
                        {city &&
                            city.map((item) => (
                                <option key={item.id} value={parseInt(item.id)}>
                                    {item.title}
                                </option>
                            ))}
                    </select>
                </span>
            )}

            <span className="form-group">
                <button className="btn btn-primary">
                    {behavior === 'create' ? 'Salvar' : 'Atualizar'}
                </button>
            </span>

            {isError && (
                <div className="row">
                    <div className="col">
                        <p className="mt-2 bg-danger text-white rounded p-3">
                            {isError || isError[Object.keys(isError)]}
                        </p>
                    </div>
                </div>
            )}
        </form>
    )
}

export default DrawerForm
