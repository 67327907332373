import React, { useState } from 'react'
import { Col, Container, Row, Tabs, Tab } from 'react-bootstrap'
import TopBar from '../components/TopBar'
import VerticalNavigation from '../components/VerticalNavigation'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'
import Card from '../components/Card'
import Rooms from '../components/Rooms'
import Schedules from '../components/Rooms/Schedules'

import api from '../api'

import style from '../scss/pages/dashboard.module.scss'

const Schedule = () => {
    const url = window.location.search
    const params = new URLSearchParams(url)
    const eventId = parseInt(params.get('event_id'))

    const [filterParam, setFilterParam] = useState('')

    const [types, setTypes] = React.useState(null)
    const [states, setStates] = React.useState([])
    const [event, setEvent] = React.useState({})

    function handleTabs(eventValue) {
        setTypes(eventValue)
    }

    function getAllEventsLocations(type) {
        api.get(`/events/locations?type=${type}`)
            .then((response) => {
                if (response.status === 200) {
                    setStates(response.data)
                }
            })
            .catch((error) => {
                console.log('ERROR: ' + error)
            })
    }

    function onSelectSquare(event) {
        api.get(`/events/locations?type=${event.type}`).then((response) => {
            if (response.status === 200) {
                const { data } = response
                data.filter(({ city_id }) => event.city_id === city_id).map(
                    (item) => {
                        setFilterParam(item.state_uf)
                    }
                )
            }
        })
    }

    React.useEffect(() => {
        if (eventId) {
            api.get(`/events/${eventId}`).then((response) => {
                if (response.status === 200) {
                    const { data } = response
                    setTypes(data.type)
                    onSelectSquare(data)

                    setEvent(data)
                }
            })
        }
    }, [])

    React.useEffect(() => {
        getAllEventsLocations(types)
    }, [filterParam, types])

    return (
        <section className={`content ${style.dashboard} schedules`}>
            <TopBar />
            <Spacer size={90} />
            <Container>
                <Row>
                    <Col lg={3}>
                        <VerticalNavigation />
                    </Col>
                    <Col lg={9}>
                        <Row>
                            <Col>
                                <h2 className="title blue">
                                    Reservas{' '}
                                    <span>
                                        &bull;{' '}
                                        {(types === null &&
                                            'Sala de reunião') ||
                                            (types === 'meeting_room' &&
                                                'Sala de reunião') ||
                                            (types === 'workspace' &&
                                                'Estação de Trabalho') ||
                                            (types === 'chat_room' &&
                                                'Sustentação Oral')}
                                    </span>
                                </h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <Row>
                                        <Col lg="12">
                                            <Tabs
                                                defaultActiveKey={types}
                                                activeKey={types}
                                                id="uncontrolled-tab-example"
                                                className="mb-3"
                                                onSelect={handleTabs}
                                            >
                                                {[
                                                    {
                                                        type: 'meeting_room',
                                                        title: 'Sala de reunião',
                                                    },
                                                    {
                                                        type: 'workspace',
                                                        title: 'Estação de Trabalho',
                                                    },
                                                    {
                                                        type: 'chat_room',
                                                        title: 'Sustentação Oral',
                                                    },
                                                ].map((item) => (
                                                    <Tab
                                                        key={item.type}
                                                        eventKey={item.type}
                                                        title={item.title}
                                                    ></Tab>
                                                ))}
                                            </Tabs>
                                        </Col>

                                        <Col lg="6">
                                            <div className="wrapperSelect">
                                                <select
                                                    className="form-control customSelect"
                                                    value={filterParam}
                                                    onChange={(e) => {
                                                        setFilterParam(
                                                            e.target.value
                                                        )
                                                    }}
                                                >
                                                    <option value="">
                                                        Praça
                                                    </option>
                                                    {states.length === 0 ? (
                                                        <option
                                                            value=""
                                                            disabled
                                                        >
                                                            Nenhum registro
                                                            encontrado.
                                                        </option>
                                                    ) : (
                                                        states &&
                                                        states.map(
                                                            ({
                                                                city_id,
                                                                city_name,
                                                                state_uf,
                                                            }) => (
                                                                <option
                                                                    key={
                                                                        city_id
                                                                    }
                                                                    value={
                                                                        state_uf
                                                                    }
                                                                >
                                                                    {city_name}
                                                                </option>
                                                            )
                                                        )
                                                    )}
                                                </select>
                                                <svg
                                                    width="43"
                                                    height="43"
                                                    viewBox="0 0 43 43"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect
                                                        width="43"
                                                        height="43"
                                                        rx="21.5"
                                                        fill="#15599D"
                                                    />
                                                    <path
                                                        d="M15 21.1118C15.0055 17.1158 17.8183 14.1581 21.3157 14.0067C24.9333 13.8497 27.9738 16.487 28.298 20.0655C28.4568 21.6086 28.0474 23.1567 27.1463 24.4195C26.9461 24.7052 26.9956 24.8348 27.217 25.0475C28.0578 25.8622 28.8852 26.6911 29.7072 27.5247C30.1272 27.9509 30.0864 28.5318 29.6412 28.8457C29.288 29.0954 28.8711 29.0475 28.5147 28.6935C27.6742 27.8583 26.8389 27.0195 26.0088 26.177C25.8282 25.9925 25.7215 25.9415 25.471 26.1126C23.2603 27.5938 20.9177 27.7798 18.5673 26.5483C16.2168 25.3167 15.0981 23.2727 15 21.1118ZM21.6518 25.6558C22.3072 25.6594 22.9568 25.5336 23.5635 25.2856C24.1702 25.0376 24.7219 24.6723 25.187 24.2106C25.6521 23.7489 26.0215 23.1999 26.2739 22.5951C26.5264 21.9904 26.6569 21.3417 26.658 20.6864C26.6614 20.0279 26.5346 19.3751 26.2849 18.7658C26.0352 18.1564 25.6675 17.6023 25.2029 17.1355C24.7384 16.6687 24.1861 16.2982 23.5779 16.0455C22.9697 15.7928 22.3175 15.6627 21.6588 15.6629C20.3351 15.6684 19.0673 16.1967 18.1314 17.1326C17.1955 18.0685 16.6673 19.3363 16.662 20.6597C16.6628 21.9833 17.1885 23.2525 18.1239 24.1891C19.0593 25.1256 20.328 25.6531 21.6518 25.6558Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </div>
                                        </Col>
                                    </Row>
                                    {filterParam !== '' && (
                                        <Rooms
                                            location={filterParam}
                                            types={types}
                                            setLocation={setFilterParam}
                                            setTypes={setTypes}
                                            event={event}
                                        />
                                    )}
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Schedules />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Footer colorBlue={false} />
        </section>
    )
}

export default Schedule
