import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import TopBar from '../components/TopBar'
import VerticalNavigation from '../components/VerticalNavigation'
import WarningList from '../components/WarningList'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'
import RightSidebar from '../components/RightSidebar'
import PostList from '../components/PostList'

import api from '../api'

import style from '../scss/pages/dashboard.module.scss'

const Home = () => {
    return (
        <section className={`content ${style.dashboard}`}>
            <TopBar />
            <Spacer size={90} />
            <Container>
                <Row className="flex-column-reverse flex-lg-row">
                    <Col lg={3}>
                        <VerticalNavigation />
                    </Col>
                    <Col lg={6}>
                        <WarningList />
                        <PostList />
                    </Col>
                    <Col lg={3}>
                        <RightSidebar />
                    </Col>
                </Row>
            </Container>
            <Footer colorBlue={false} />
        </section>
    )
}

export default Home
