import React from 'react';
import {Navigate} from 'react-router-dom';

function PrivateRoute({children}) {
    const user = localStorage.getItem('user');
    if (!user) {
        // user is not authenticated
        return <Navigate to="/"/>;
    }
    return children;

}

export {PrivateRoute};