import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import style from './style.module.scss'

const PostBox = ({ author, children, publishDate, title, thumbnail, link }) => {
    const formatedDate = format(new Date(publishDate), 'dd/MM/yyyy')

    return (
        <div className={style.postBox}>
            <a href={link} target="_blank" style={{ textDecoration: 'none' }}>
                <h2 className={style.title}>{title}</h2>
            </a>
            <div className={style.content}>
                {thumbnail && <img src={thumbnail} alt={title} />}
            </div>
            <div
                className={style.content}
                dangerouslySetInnerHTML={{ __html: children }}
            ></div>
            <div className={style.postFooter}>
                {author ? (
                    <p>
                        Publicado por <span>{author}</span> em {formatedDate}
                    </p>
                ) : (
                    <p>Publicado em {formatedDate}</p>
                )}
            </div>
        </div>
    )
}

export default PostBox

PostBox.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    author: PropTypes.string,
    publishDate: PropTypes.string,
}
