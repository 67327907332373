import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import Loader from '../components/Loader'
import TopBar from '../components/TopBar'
import VerticalNavigation from '../components/VerticalNavigation'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'
import Card from '../components/Card'
import Button from '../components/Button'
import Masonry from 'react-masonry-css'
import api from '../api'
import axios from 'axios'

import style from '../scss/pages/dashboard.module.scss'

const Comunication = () => {
    const [loading, setLoading] = useState(true)
    const [communications, setCommunications] = useState([])
    const [communicationsLinks, setCommunicationsLinks] = useState([])

    const fetchData = () => {
        setLoading(true)

        const communicationURL = '/communications'
        const communicationsLinksURL = '/communication-links'

        const dataCommunications = api.get(communicationURL)
        const dataCommunicationsLinks = api.get(communicationsLinksURL)

        axios
            .all([dataCommunications, dataCommunicationsLinks])
            .then(
                axios.spread((...responses) => {
                    setCommunications(responses[0].data)
                    setCommunicationsLinks(responses[1].data)
                    setLoading(false)
                })
            )
            .catch((err) => {
                console.error(
                    'Ops! Houve um erro ao realizar as requisições: ' + err
                )
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    function validateURL(url) {
        const valid = /^(ftp|http|https):\/\/[^ "]+$/.test(url)
        return valid
    }

    const breakpointColumnsObj = {
        default: 2,
        767: 1,
    }

    const handleDownload = (id) => {
        api.get(`/attachments/download/${id}`, { responseType: 'blob' })
            .then((response) => {
                const blob = response.data
                const contentType = response.headers['content-type']
                const contentDisposition =
                    response.headers['content-disposition']

                let filename = ''

                if (
                    contentDisposition &&
                    contentDisposition.indexOf('attachment') !== -1
                ) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                    let matches = filenameRegex.exec(contentDisposition)
                    if (matches != null && matches[1])
                        filename = matches[1].replace(/['"]/g, '')
                }

                const file = new Blob([blob], { type: contentType })
                const fileURL = URL.createObjectURL(file)

                if (filename) {
                    let linkDownload = document.createElement('a')

                    linkDownload.setAttribute('href', fileURL)
                    linkDownload.setAttribute('download', filename)

                    linkDownload.click()
                }
            })
            .catch((error) => console.log(error))
    }

    return (
        <section className={`content ${style.dashboard}`}>
            <TopBar />
            <Spacer size={90} />
            <Container>
                <Row>
                    <Col lg={3}>
                        <VerticalNavigation />
                    </Col>
                    <Col lg={9}>
                        <Row>
                            <Col>
                                <h2 className="title blue">Comunicação</h2>
                            </Col>
                        </Row>
                        <Row>
                            {communications.length === 0 &&
                                communicationsLinks.length === 0 &&
                                !loading && (
                                    <Card
                                        title="Nada foi encontrado!"
                                        titleGray
                                    />
                                )}
                        </Row>
                        {loading ? (
                            <Loader />
                        ) : (
                            <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid"
                                columnClassName="my-masonry-grid_column"
                            >
                                {communications.map((communication) => (
                                    <div key={communication.id}>
                                        <Card
                                            title={communication.title}
                                            titleGray
                                            imageUrl={communication.image}
                                        >
                                            <br />
                                            {communicationsLinks
                                                .filter(
                                                    (communicationLink) =>
                                                        communication.id ===
                                                        communicationLink.communication_id
                                                )
                                                .map(
                                                    ({
                                                        title,
                                                        url,
                                                        attachment_id,
                                                    }) => (
                                                        <>
                                                            {url.includes(
                                                                'mailto'
                                                            ) ||
                                                            validateURL(url) ? (
                                                                <Button
                                                                    text={title}
                                                                    url={url}
                                                                    target="_blank"
                                                                />
                                                            ) : (
                                                                <Button
                                                                    text={title}
                                                                    isDonwload
                                                                    onClick={() => {
                                                                        handleDownload(
                                                                            attachment_id
                                                                        )
                                                                    }}
                                                                />
                                                            )}
                                                        </>
                                                    )
                                                )}
                                        </Card>
                                    </div>
                                ))}
                            </Masonry>
                        )}
                    </Col>
                </Row>
            </Container>
            <Footer colorBlue={false} />
        </section>
    )
}

export default Comunication
