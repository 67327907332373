import React from 'react'
import { Col } from 'react-bootstrap'

import Button from '../Button'
import Spacer from '../Spacer'

import style from './style.module.scss'

const UsersEmailPhoneBox = ({ name, info, link }) => {
    let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    )

    if (pattern.test(info)) {
        return (
            <Col lg={4}>
                <div className={style.usersEmailBox}>
                    {name && <h4>{name}</h4>}
                    {info && <a href={`mailto:${info}`}>{info}</a>}
                    {link && (
                        <>
                            <Spacer size={12} />
                            <Button
                                typeTwo
                                noIcon
                                text="Acesse o link"
                                target="_blank"
                                url={link}
                            />
                        </>
                    )}
                </div>
            </Col>
        )
    } else {
        return (
            <Col lg={4}>
                <div className={style.usersEmailBox}>
                    {name && <h4>{name}</h4>}
                    {info && <span>{info}</span>}
                    {link && (
                        <>
                            <Spacer size={12} />
                            <Button
                                typeTwo
                                noIcon
                                text="Acesse o link"
                                target="_blank"
                                url={link}
                            />
                        </>
                    )}
                </div>
            </Col>
        )
    }
}

export default UsersEmailPhoneBox
